export default defineNuxtPlugin(() => {
  if (process.client) {
    // nuxtApp.hook('page:start', () => {
    //   document.querySelectorAll('[style]').forEach((el) => {
    //     if (el.classList.contains('van-swipe__track') || el.classList.contains('van-swipe-item')) return
    //     const style = el.getAttribute('style')
    //     // 使用正则匹配内联样式 替换px为vw
    //     const newStyle = style?.replace(/(\d+)px/g, (match, p1) => `${(p1 / 375) * 100}vw`)
    //     newStyle && el.setAttribute('style', newStyle)
    //   })
    // })
  }
})
