// import { ElMessage } from 'element-plus'
import { i18nt } from '@/plugins/i18n'
import { showToast } from 'vant'
import { useUserStore } from '@/stores'
import { v4 as uuid } from 'uuid'

// 后端返回的数据类型
export interface ResOptions<T> {
  data?: T
  code?: number
  msg?: string
}

const fetch = (url: string, configs?: any, showErrorToast?: boolean): Promise<any> => {
  const VITE_API_URL = import.meta.env.VITE_API_URL as string
  // 不设置key，始终拿到的都是第一个请求的值，参数一样则不会进行第二次请求
  const key = uuid()
  return new Promise((resolve, reject) => {
    useFetch(url, {
      ...configs,
      key,
      baseURL: VITE_API_URL,
      onRequest(context: any) {
        const { options } = context
        const userStore = useUserStore()
        const globalConfig = useGlobalConfigStore()
        let sessionId = ''
        try {
          const session = (window as any)?.dataAc?.util?.getCookie('ACUSID')
          sessionId = session && JSON.parse(session).key
        } catch (error) {
          console.log(error)
        }
        options.headers = options.headers || {}
        // options.headers.userid = 1
        if (!(url.indexOf('http') == 0)) {
          if (userStore.token) {
            options.headers.Authorization = 'Bearer ' + userStore.token
          }
          // lang 中英韩文
          options.headers.lang = userStore.lang
          options.headers['X-Client'] = 'H5'
          options.headers['X-Sessid'] = sessionId
          const route = useRoute()
          if ((globalConfig.config.utmRet === undefined || globalConfig.config.utmRet) && route.query.utm) {
            options.headers['X-UTM'] = route.query.utm
          }
        } else {
          // options.headers.userId = 47
        }
      },
      onResponse(context: any) {
        try {
          const { response } = context
          const requestId = response.headers.get('x-request-id')
          const url = response.url
          if (response.status === 200 && (JSON.stringify(response._data.data) === '{}' || !response._data.data)) {
            ;(window as any).apmPlus('sendEvent', {
              name: 'custom_response_error',
              categories: {
                requestId,
                url,
              },
            })
          }
          const xBucket = response.headers.get('x-bucket')
          if (xBucket !== null) {
            const _xBucket = String(xBucket)
            const localXBucket = sessionStorage.getItem('x-bucket')
            if (localXBucket != _xBucket) {
              sessionStorage.setItem('x-bucket', _xBucket)
            }
          }
        } catch (error) {}
      },
    })
      .then((res) => {
        const { data, error } = res
        if (error.value) {
          // const status = error.value.statusCode
          const data = error.value.data
          // const msg = (lang as any).httpErrorCode[data?.code]
          // ElMessage.error(msg || data?.msg || 'Error!')
          // 450 账号被禁用
          // 451, //token 错误：token 为空，token失效等等
          // 452, //游客访问了自己没有权限的接口
          if (data?.code == 451 || data?.code == 452 || data?.code == 455) {
            if (data?.code == 452) {
              useUserStore().setLastPage(useRoute().fullPath)
              navigateTo('/login')
            } else if (data?.code == 451) {
              useUserStore().clear()
              location.href = '/login'
            } else if (data?.code == 455) {
              showToast({
                message: data?.msg || i18nt('h5.utils.http.serveError'),
                duration: 1500,
                zIndex: 9999,
              })
              //延迟1500
              setTimeout(() => {
                navigateTo('/')
              }, 1500)
            }
          } else {
            showErrorToast &&
              showToast({
                message: data?.msg || i18nt('h5.utils.http.serveError'),
                duration: url.indexOf('client/third/login') >= 0 && configs?.body.sourceId == ThirdLoginType.KAKAO ? 5000 : 2000,
                zIndex: 9999,
              })
            if (data?.code == 450) {
              navigateTo('/login')
            }
          }
          reject(error.value)
          return
        }
        resolve(data?.value)
      })
      .catch((err: any) => {
        reject(err)
      })
  })
}

export default new (class Http {
  get = (url: string, params?: any, otherConfig?: any, showErrorToast = true) => {
    return fetch(url, { method: 'get', params, ...(otherConfig || {}) }, showErrorToast)
  }

  post = (url: string, body?: any, otherConfig?: any, showErrorToast = true) => {
    return fetch(url, { method: 'post', body, ...(otherConfig || {}) }, showErrorToast)
  }

  put = (url: string, body?: any, otherConfig?: any, showErrorToast = true) => {
    return fetch(url, { method: 'put', body, ...(otherConfig || {}) }, showErrorToast)
  }

  delete = (url: string, body?: any, otherConfig?: any, showErrorToast = true) => {
    return fetch(url, { method: 'delete', body, ...(otherConfig || {}) }, showErrorToast)
  }
})()
