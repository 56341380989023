import clipboard from 'copy-text-to-clipboard';
export const copyText = (text) => {
    return new Promise((resolve, reject) => {
        if (clipboard(text)) {
            resolve();
        }
        else {
            reject();
        }
    });
};
export const debounce = function (fun, delay) {
    return function (args) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore: Unreachable code error
        // eslint-disable-next-line @typescript-eslint/no-this-alias
        const that = this;
        const _args = args;
        clearTimeout(fun.id);
        fun.id = setTimeout(function () {
            fun.call(that, _args);
        }, delay);
    };
};
export const regexpEmail = /^([A-Za-z0-9_\-.])+@([A-Za-z0-9_\-.])+\.([A-Za-z]{2,})$/;
export const regexpPwd = /^(?=.*[0-9].*)(?=.*[A-Z].*)(?=.*[a-z].*).{8,}$/; // 8位以上，包含数字、字母大小写
/**
 * 格式化时间
 * @param timestamp 原始值
 * @param format 格式化字符串 YYYY-MM-DD hh:mm:ss
 * @returns string
 */
export const timestampToTime = (timestamp, format = 'YYYY-MM-DD hh:mm:ss') => {
    if (!timestamp) {
        return '';
    }
    let ret = format;
    if (isNaN(Number(timestamp))) {
        if (typeof timestamp == 'string') {
            if (String(new Date(timestamp)) == 'Invalid Date') {
                timestamp = timestamp.replaceAll('-', '/');
            }
        }
    }
    else {
        timestamp = Number(timestamp);
    }
    const date = new Date(timestamp);
    const Y = date.getFullYear();
    ret = ret.replace('YYYY', String(Y));
    const M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) : date.getMonth() + 1;
    ret = ret.replace('MM', String(M));
    const D = date.getDate() < 10 ? '0' + date.getDate() : date.getDate();
    ret = ret.replace('DD', String(D));
    const h = date.getHours() < 10 ? '0' + date.getHours() : date.getHours();
    ret = ret.replace('hh', String(h));
    const m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
    ret = ret.replace('mm', String(m));
    const s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
    ret = ret.replace('ss', String(s));
    // 处理只有YY的情况
    ret = ret.replace('YY', String(Y).substring(2, 4));
    return ret;
};
/**
 * 判断一个值是否是数字
 * @param val 判断值
 * @param isInt 是否是整数
 * @returns boolean
 */
export const isNum = (val, isInt) => {
    if (val === '') {
        return false;
    }
    const n = Number(val);
    if (isNaN(n)) {
        return false;
    }
    if (isInt && parseInt(String(val), 10) != n) {
        return false;
    }
    return true;
};
export default {
    copyText,
    debounce,
    regexpEmail,
    regexpPwd,
    timestampToTime,
    isNum,
};
