import { createPinia } from 'pinia'
// import piniaPluginPersist from 'pinia-plugin-persist'

import { useOrderStore } from '@/stores/module/useOrderStore'
import { useEventStore } from '@/stores/module/useEventStore'
import { useUserStore } from '@/stores/module/useUserStore'
import { useGlobalConfigStore, type GlobalConfigStore } from '@/stores/module/useGlobalConfig'
import { useHistoryStore } from '@/stores/module/useHistory'
import { useAttrStore } from '@/stores/module/useAttr'

const store = createPinia()

export { useUserStore, useOrderStore, useGlobalConfigStore, type GlobalConfigStore, useHistoryStore, useAttrStore, useEventStore }

export default store
