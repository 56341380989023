export const appHead = {"meta":[{"charset":"utf-8"},{"name":"keywords","content":"iCOOL, iCool, icool"},{"name":"description","content":"iCOOL"},{"name":"viewport","content":"width=device-width,user-scalable=no,initial-scale=1.0,maximum-scale=1.0,minimum-scale=1.0"},{"name":"google-site-verification","content":"Gq4lvJZl4lVuaWBaaqszuezPuIvwkOC_EOEjZ6E99Lo"},{"name":"naver-site-verification","content":"b80778f053c4d860d8a99c8780e69a5040ca64cd"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.ico"}],"style":[],"script":[],"noscript":[],"title":"iCOOL"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const appManifest = true

export const remoteComponentIslands = false

export const devPagesDir = null

export const devRootDir = null

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'