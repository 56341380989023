import { UMENGCUSTOMTYPE } from '../umeng'
export function sendEvent(event_code: UMENGCUSTOMTYPE, params: UMengParams, useUmeng: boolean = true, useAC: boolean = true, addPageRouter: boolean = true) {
  console.log('sendEvent', event_code, params, useUmeng, useAC, addPageRouter)
  if (!event_code) {
    return
  }
  params.Um_Key_UserID = getUserId()
  const userStore = useUserStore()
  const history = useHistoryStore()
  if (userStore?.homePage) {
    params.Um_Key_UserLevel = userStore?.homePage?.role
    params.Um_Key_UserNick = userStore?.homePage?.nickname
  }

  const { aplus_queue } = window as any
  if (aplus_queue && useUmeng) {
    if (addPageRouter) {
      params.Um_Key_PageRouter = history.getHistoryValues().join('|')
    }
    aplus_queue.push({
      action: 'aplus.record',
      arguments: [event_code.toString(), 'CLK', params],
    })
  }
  // 同时发送给dataAc
  if (userStore?.userInfo) {
    const homePage = userStore.homePage
    Object.assign(params, {
      uin: homePage?.nickname || '',
      uia: homePage?.avatar || '',
      uig: homePage?.gender || '',
      uil: userStore.lang,
    })
  }
  if (addPageRouter) {
    ;(params as any).pageRouter = history.getHistoryValues().join('|')
  }
  useAC && (window as any).dataAc?.pushCutData({ eventName: event_code.toString(), eventVariable: JSON.stringify(params ?? {}) })
}
