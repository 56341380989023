import { type NavigationGuardNext } from 'vue-router'
export default defineNuxtPlugin((nuxtApp) => {
  const router = nuxtApp.$router as any
  const loadingInstance = ref()
  nuxtApp.provide('setLoadingRef', (ref: any) => {
    loadingInstance.value = ref
  })
  router.beforeEach((to: any, from: any, next: NavigationGuardNext) => {
    if (loadingInstance.value) {
      loadingInstance.value.show()
    }
    next()
  })

  router.afterEach(() => {
    if (loadingInstance.value) {
      loadingInstance.value.hide()
    }
  })
})
