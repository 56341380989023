<template>
  <div class="box404">
    <div class="cont404">
      <img src="/images/base/404-empty.png" alt="" srcset="" />
      <div class="txt">{{ $t('h5.pages.404.404') }}</div>
      <van-button type="primary" data-ac="other_404" @click="$router.back()">{{ $t('h5.pages.404.back') }}</van-button>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps<{ error: any }>()
console.log('error.vue', props.error)
// const props = defineProps<{ error: any }>()

// let statusCode = props.error?.statusCode || 500

// if (statusCode === 404) {
//   // 跳轉到自訂的 404 頁面
//   clearError({ redirect: '/404' })
// }
</script>
<style lang="scss" scoped>
.box404 {
  min-height: 100vh;
  box-sizing: border-box;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .cont404 {
    width: 375px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    img {
      height: 106px;
      width: auto;
    }
    .txt {
      color: $black6;
      font-size: 15px;
      font-weight: 500;
      line-height: 160%;
      text-align: center;
      margin: 8px 0 16px;
    }
    .van-button {
      height: 36px;
      min-width: 124px;
      font-size: 14px;
      font-weight: 500;
      line-height: 140%;
      background-color: $black10;
      color: $white;
      border: none;
      border-radius: 2px;
    }
  }
}
</style>
