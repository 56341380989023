import { defineStore } from 'pinia'
import { i18n } from '@/plugins/i18n'
import { getOwnerInfo } from '@/composables/user'
// import { useApiGetUserInfo } from '@/composables/client'
interface UserStore {
  lang: 'en_US' | 'ko_KR' | ''
  userInfo: {
    apple: boolean
    email: string
    facebook: boolean
    google: boolean
    kakaoTalk: boolean
    naver: boolean
    loginPwd: boolean
    tel: string
    userId: string
    finishGuide: boolean
  } | null
  homePage: {
    userId: string
    nickname: string
    avatar: string
    role: number //1:普通 2:艺术家 3:博主
    email: string
    gender: string
    introduction: string
    integral: number
    fansCount: number
    followCount: number
    likeCount: number
    collectCount: number
    preferences: Array<any>
    finishGuide: boolean
  } | null
  token: string
  lastPage: string
  exitComplete: boolean //退出完成,为true表示退出完成
  cartCount: number //购物车数量
  notifyRefreshCart: boolean //主动通知购物车刷新
  couponInfo: {
    couponNo: string
    discountAmount: string
    discountType: string
    activityId: string
    filters: string
    activity: {
      activityId: string
      tip: Array<string>
      oneGoodsTip: Array<string>
      linkUrl: string
      linkWord: Array<string>
      showOneGoods: boolean
    }
  } | null
}
export const useUserStore = defineStore({
  id: 'user',
  state: (): UserStore => {
    return {
      lang: '',
      userInfo: null,
      homePage: null,
      token: '',
      lastPage: '',
      cartCount: 0,
      couponInfo: null,
      exitComplete: false,
      notifyRefreshCart: false,
    }
  },
  actions: {
    setUserInfo(params: any) {
      // 手动处理 存放uid到localStorage中 用户id: ACUUID 游客id: ACUTID,用户token: ACUTOKEN
      this.userInfo = params
      localStorage.setItem('ACUUID', params.userId)
      localStorage.removeItem('ACUTID')
    },
    setAvatar(avatar: string) {
      if (this.homePage) {
        this.homePage.avatar = avatar
      }
    },
    setCartNumber(count: number) {
      this.cartCount = count
    },
    setLastPage(page: string) {
      this.lastPage = page
    },
    setExitComplete(exit: boolean) {
      this.exitComplete = exit
    },
    setNotifyRefreshCart(refresh: boolean) {
      this.notifyRefreshCart = refresh
    },
    setToken(token: string) {
      localStorage.setItem('ACUTOKEN', token)
      this.token = token
    },
    setHomePage(params: any) {
      this.homePage = params
    },
    setCouponInfo(params: any) {
      this.couponInfo = params
    },
    /**
     * 更新用户信息
     * @param notUpdateAvatar 非必要重新获取头像
     */
    updateHomePage(notUpdateAvatar?: boolean) {
      getOwnerInfo().then((res: any) => {
        if (notUpdateAvatar) {
          if ((this.homePage?.avatar || '').includes('http') && (this.homePage?.avatar || '').includes(res.data.avatar)) {
            const newData = JSON.parse(JSON.stringify(res.data))
            newData.avatar = this.homePage?.avatar
            this.setHomePage(newData)
            return
          }
        }
        imgToShow([res.data.avatar], { resize: 120 }).then((r: any) => {
          res.data.avatar = r[0]
          this.setHomePage(res.data)
        })
      })
    },
    // logout() {
    //   this.userInfo = null
    //   this.homePage = null
    //   this.token = ''
    //   navigateTo('/login')
    // },
    setLang(lang?: 'en_US' | 'ko_KR') {
      if (lang) {
        this.lang = lang
      } else {
        if (process.client) {
          if (!this.lang) {
            this.lang = window.navigator.language?.substring(0, 2)?.toLocaleLowerCase() == 'en' ? 'en_US' : 'ko_KR'
          }
        }
      }
      i18n.global.locale = lang || this.lang || 'ko_KR'
    },
    clear() {
      this.token = ''
      this.userInfo = null
      this.homePage = null
      this.couponInfo = null
      localStorage.removeItem('ACUTOKEN')
      localStorage.removeItem('ACUUID')
    },
  },
  persist: {
    // homePage 数据过多，不持久化，每次加载更新即可
    paths: ['lang', 'userInfo', 'token', 'lastPage', 'homePage.nickname', 'homePage.avatar', 'homePage.role', 'homePage.email', 'homePage.finishGuide'],
    storage: persistedState.localStorage,
  },
  // 默认cookie，可选用
  // persist: {
  //   storage: persistedState.localStorage,
  // },
})
