<template>
  <div v-if="loading" class="loading-overlay">
    <img src="/images/routerLoadign.gif" style="width: 70px; height: 70px" alt="" srcset="" />
  </div>
</template>

<script setup>
import { ref } from 'vue'

const loading = ref(false)

const show = () => {
  loading.value = true
}

const hide = () => {
  loading.value = false
}

defineExpose({ show, hide })
</script>

<style>
.loading-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 20px;
  z-index: 10000;
  img {
    width: 72px;
    height: 72px;
  }
}
</style>
