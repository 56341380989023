import YSF from '@neysf/qiyu-web-sdk'
import { useUserStore } from '@/stores/module/useUserStore'

let ysf: any

export const initKf = async () => {
  const userStore = useUserStore()
  if (userStore.token && userStore.userInfo) {
    try {
      ysf = await YSF.init(
        'ad8c1d93cd5544d9d5cd721ab2606f24',
        {
          templateId: 6638329, // templateId表示自定义会话邀请的模板标识
          // shuntId: 123, // shuntId表示访客选择多入口分流模版id
          // sessionInvite: false, // sessionInvite表示是否开启会话邀请设置
          hidden: true, // hidden表示是否隐藏访客端默认入口
        },
        'overseas',
      )
    } catch (error) {
      console.log('sdk加载失败---', error)
    }
  }

  // YSF.init(
  //   'ad8c1d93cd5544d9d5cd721ab2606f24',
  //   {
  //     templateId: 6638329, // templateId表示自定义会话邀请的模板标识
  //     // shuntId: 123, // shuntId表示访客选择多入口分流模版id
  //     // sessionInvite: false, // sessionInvite表示是否开启会话邀请设置
  //     hidden: true, // hidden表示是否隐藏访客端默认入口
  //   },
  //   'overseas',
  // )
  //   .then((ysf) => {
  //     ysf('config', {
  //       language: userStore.lang.split('_')[0] || 'ko',
  //       uid: userStore.userInfo?.userId || '',
  //       name: userStore.homePage?.nickname || '',
  //     })
  //     // ysf('open')
  //   })
  //   .catch((error) => {
  //     console.log('sdk加载失败---', error)
  //   })
}

export const toKf = () => {
  const userStore = useUserStore()
  if (userStore.token && userStore.userInfo) {
    if (ysf) {
      ysf('config', {
        language: userStore.lang.split('_')[0] || 'ko',
        uid: `${userStore.userInfo?.userId || ''}_${userStore.lang.split('_')[0] || 'ko'}`,
        name: `${userStore.homePage?.nickname || ''}_${userStore.lang.split('_')[0] || 'ko'}`,
      })
      ysf('open')
    } else {
      initKf()
    }
  }
}

export const logoff = () => {
  const userStore = useUserStore()
  if (userStore.token && userStore.userInfo) {
    if (ysf) {
      ysf('logoff')
    }
  }
}
