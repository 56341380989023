export interface UMengParams {
  //加入购物车、立即购买、商品取消订单、评论商品、查看商品详情、收藏商品、分享商品、取消收藏商品、支付订单详情、提交订单详情
  Um_Key_ItemName?: string //商品名称
  //加入购物车、立即购买、商品取消订单、评论商品、查看商品详情、收藏商品、分享商品、取消收藏商品、资源位点击、资源位曝光、支付订单详情、提交订单详情
  Um_Key_ItemID?: string //商品ID
  Um_Key_ItemCategory?: string //商品分类
  Um_Key_ItemPrice?: number // 商品价格
  //全部事件
  Um_Key_UserID?: string // 用户ID
  Um_Key_UserLevel?: number // 用户等级
  Um_Key_UserNick?: string // 用户昵称
  //售后、商品取消订单、支付订单详情、支付订单成功、提交订单、提交订单详情
  Um_Key_OrderID?: string //订单ID
  //售后
  Um_Key_Reason?: string //退换货原因
  //商品取消订单、支付订单详情、支付订单成功、提交订单
  Um_Key_OrderPrice?: number //订单总价
  //优惠券领取、支付订单成功、提交订单
  Um_Key_CouponName?: string //优惠券名称
  //优惠券领取
  Um_Key_CouponID?: string //优惠券ID
  Um_Key_CouponDenomination?: string //优惠券金额
  Um_Key_CouponExpireTime?: string //过期时间
  Um_Key_CouponNum?: number //领取数量
  Um_Key_CouponSource?: string //优惠券领取渠道
  //查看商品详情
  Um_Key_ItemPraise?: number //商品点赞数
  Um_Key_SourcePage?: string //来源页面
  Um_Key_SourceLocation?: string //来源位置
  //资源位点击、资源位曝光
  Um_Key_FocusMapRotatePlace?: string //资源ID
  //支付订单成功、提交订单
  Um_Key_ItemNum?: number //商品件数
  Um_Key_OrderDiscount?: string //商品折扣
  Um_Key_UseCoupon?: string //是否使用优惠券
  Um_Key_CouponDiscount?: string //优惠券折扣
  Um_Key_PaymentType?: string //支付方式
  Um_Key_CreateOrderPage?: string //创建订单页面

  Um_Key_TimeOnPage?: string // 页面停留时长
  Um_Key_PageUrl?: string // 页面url
  Um_Key_PageRouter?: string // 页面路由历史

  // 精选创意
  Um_Key_PostID?: string // 帖子ID
  Um_Key_PostTitle?: string // 帖子标题
  Um_Key_PostType?: string // 帖子类型
  Um_Key_PostArea?: string // 帖子区域
  //模版定制
  Um_Key_PlayMethodId?: string //对应管理端的玩法id
  Um_Key_PlayId?: string //具体玩法id
  Um_Key_SkuId?: string //sku id
  Um_Key_RId?: string //模版id
}

export function uploadUserProfile() {
  const userStore = useUserStore()
  if (userStore?.userInfo) {
    const user = userStore.userInfo
    const homePage = userStore.homePage
    const mode = import.meta.env.VITE_MODE as 'dev' | 'test' | 'uat' | 'prod'
    const ui = {
      ak: mode == 'prod' ? '66541e18940d5a4c495f60d6' : '662b5adb940d5a4c49491066',
      sdt: 'h5mp',
      aid: '',
      uin: homePage?.nickname || '',
      uia: homePage?.avatar || '',
      uig: homePage?.gender || '',
      uit: '',
      uip: '',
      uic: '',
      uil: userStore.lang,
      id: user.userId,
      it: 'uuid', //请与aplus-idtype值保持一致
    }
    const { aplus, aplus_queue } = window as any
    aplus?.uploadUserProfile(JSON.stringify(ui), function (res: any) {
      console.log('uploadUserProfile: ', res)
    })
    aplus_queue?.push({
      action: 'aplus.setMetaInfo',
      arguments: ['_user_id', user.userId],
    })
    aplus_queue?.push({
      action: 'aplus.setMetaInfo',
      arguments: ['uuid', user.userId],
    })
    aplus_queue?.push({
      action: 'aplus.setMetaInfo',
      arguments: ['aplus-idtype', 'uuid'],
    })
  }
}

//商品点击来源
export enum SOURCETYPE {
  HOME = 'home', //首页
  MALL = 'mall', //商城
  CART = 'cart', //购物车
  CART_RECOMMEND = 'cart_recommend', //购物车推荐
  EXPIRED = 'expired', //过期商品
  ORDER = 'order', //订单
  MALL_SEARCH = 'mall_search', //搜索
  COMMENT = 'comment', //评论
  AUTHOR = 'author', //作者
  CREATIVE = 'creative', //创意
  ORIGINALITY = 'originality', //原创
  BROWSER = 'browser', //浏览
  COLLECT = 'collect', //收藏
  SERIES = 'series', //系列
  AUTHOR_OTHER = 'author_other', //其他
  COMMUNITY = 'community', //社区
}

//友盟自定义事件
export enum UMENGCUSTOMTYPE {
  // 商品
  UE_ITEMSHARE = 'Um_Event_ItemShare', //分享商品
  UE_ITEMFAVORITE = 'Um_Event_ItemFavorite', // 收藏商品
  UE_ITEMDETAIL = 'Um_Event_ItemDetail', // 查看商品详情
  UE_ITEMCOMMENT = 'Um_Event_ItemComment', //评论商品
  UE_COLLECTCOUPON = 'Um_Event_CollectCoupon', //优惠券领取
  UE_CANCLEORDER = 'Um_Event_CancleOrder', //删除订单
  UE_BUYSUC = 'Um_Event_BuySuc', //立即购买
  UE_AFTERSERVICE = 'Um_Event_Afterservice', //售后
  UE_ADDCART = 'Um_Event_Addcart', //加入购物车
  UE_SUBMITORDERDETAIL = 'Um_Event_SubmitOrderDetail', //提交订单详情
  UE_SUBMITORDER = 'Um_Event_SubmitOrder', //提交订单
  UE_PAYMENTSUC = 'Um_Event_PaymentSuc', //支付订单成功
  UE_PAYMENTSMARTRO = 'Um_Event_PaymentSmartro', // smartro支付打开
  UE_ORDER_DETAIL = 'Um_Event_OrderDetail', //支付订单详情
  UE_OPERATIONEXPOSURE = 'Um_Event_OperationExposure', //资源位曝光
  UE_ITEM_UNFAVORITE = 'Um_Event_ItemUnfavorite', //取消收藏商品
  Um_Event_GoodClick = 'Um_Event_GoodClick', //商品点击
  Um_Event_GoodExposure = 'Um_Event_GoodExposure', //商品曝光
  Um_Event_operationClick = 'Um_Event_operationClick', //资源位点击
  Um_Event_operationExposure = 'Um_Event_operationExposure', //资源位曝光
  //支付弹窗
  Um_Event_H5Pay_Enter = 'Um_Event_H5Pay_Enter', // 进入H5支付页面时间
  Um_Event_H5Pay_Stay_Time = 'Um_Event_H5Pay_Stay_Time', //H5支付页面停留时间
  Um_Event_H5Pay_Third = 'Um_Event_H5Pay_Third', // 使用三方支付
  Um_Event_H5Pay_Leave = 'Um_Event_H5Pay_Leave', // 离开H5支付页面时间
  // 活跃度
  Um_Event_TimeOnPage = 'Um_Event_TimeOnPage', //页面停留时间
  Um_Event_VistorVisit = 'Um_Event_VistorVisit', //访客访问页面
  Um_Event_Vistor_EnterLogin = 'Um_Event_Vistor_EnterLogin', //访客进入登录页面
  Um_Event_5sActice = 'Um_Event_5sActice', //5s以上活跃用户
  // 精选创意
  Um_Event_GoodsDetailVisit = 'Um_Event_GoodsDetailVisit', //精选创意访问商品详情
  Um_Event_CreativeClickLike = 'Um_Event_CreativeClickLike', //点击喜欢
  Um_Event_Comment = 'Um_Event_Comment', //点击评论
  Um_Event_VisitCreative = 'Um_Event_VisitCreative', //浏览精选创意页面
  Um_Event_CreativeVideo2s = 'Um_Event_CreativeVideo2s', //创意视频2s播放
  Um_Event_CreativeVideoOver = 'Um_Event_CreativeVideoOver', //创意视频播放完成
  Um_Event_CreativeOrderSuccess = 'Um_Event_CreativeOrderSuccess', //下单成功
  Um_Event_CreativeClick = 'Um_Event_CreativeClick', //创意点击
  //模板
  Um_Event_Template_UseNum = 'Um_Event_Template_UseNum', //模版使用次数
  Um_Event_Template_AIUseNum = 'Um_Event_Template_AIUseNum', //使用AI模版未下单次数
  Um_Event_Template_NormalUseNum = 'Um_Event_Template_NormalUseNum', //使用普通模版未下单次数
  Um_Event_Custom_UseNum = 'Um_Event_Custom_UseNum', //自由定制使用次数
  Um_Event_Custom_AIUseNum = 'Um_Event_Custom_AIUseNum', //使用自由定制AI功能未下单次数
  Um_Event_Custom_EditorUseNum = 'Um_Event_Custom_EditorUseNum', //使用自由定制编辑器未下单次数
  Um_Event_Template_UseAI = 'Um_Event_Template_UseAI', //使用AI模版
  Um_Event_Template_Editor = 'Um_Event_Template_Editor', //使用编辑器

  // 支付成功页
  Um_Event_PaymentSuc_GOTOINS = 'Um_Event_PaymentSuc_GOTOINS', //跳转ins
  Um_Event_Author_GOTOINS = 'Um_Event_Author_GOTOINS', //作者页跳转ins
  //点击愿望机型
  Um_Event_Model_Wishlist = 'Um_Event_Model_Wishlist', // 点击愿望机型
  UE_Event_Click_GoodDesign = 'Um_Event_Click_GoodDesign', // 点击商品自定义玩法设计
  UE_Event_Custom_GoodDesign = 'Um_Event_Custom_GoodDesign', // 商品自定义玩法设计下单
}
