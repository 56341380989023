export default {
    app: {
        title: 'iCOOL',
    },
    components: {
        balance: {
            empty: {
                noMore: 'No more bills at this time',
            },
            itemBox: {
                income: 'Income',
                purchase: 'Purchase',
                withdraw: 'Withdraw',
                buyOrder: 'Buy Order',
                refund: 'Refund',
                basefee: 'Base Fee',
                settlementDividendFee: 'Settlement Dividend Fee',
                settlementCommissionFee: 'Settlement Commission Fee',
            },
            monthBox: {
                confirm: 'Confirm',
            },
            typeBox: {
                all: 'All',
                incomeSelect: 'Income',
                purchaseSelect: 'Purchase',
                withdrawSelect: 'Withdraw',
                refundSelect: 'Refund',
            },
        },
        base: {
            cotdPreSale: {
                info: '*This product is on pre-sale and will ship within 7 days of your order.*',
            },
            addressPopup1: {
                back: 'Back',
            },
            commentItem: {
                expand: '\nExpand',
                collapse: '\nCollapse',
                totalOfN: 'Total of {0}',
            },
            dialog: {
                reminders: 'Reminders',
                confirm: 'Confirm',
                cancel: 'Cancel',
            },
            footer: {
                contactiCOOL: 'Subscribe to iCOOL Newsletter.',
                emailAddress: 'Email address',
                join: 'Submit',
                aboutiCOOL: 'About iCOOL',
                contactUs: 'Contact Us',
                exchangeReturnPolicy: 'Exchange/Return Policy',
                deliveryPolicy: 'Delivery Policy',
                userAgreement: 'User agreement',
                termsService: 'Terms of Service',
                privacyAgreement: 'Privacy agreement',
                faq: 'FAQ',
                CopyrightByiCOOL: 'Copyright by iCOOL. All Rights Reserved',
                business1: 'Business Registration: 172-81-03373',
                business2: 'Mail Order License: 2024-부천오정-0187',
                emailErrTip: 'Please enter a valid email',
                submitSuccess: 'Submit successfully',
                address1: 'Address: 3F 72, Samjak-ro 95beon-gil, Ojeong-gu, Bucheon-si, Gyeonggi-do, Republic of Korea',
                address2: '22F 83, Uisadang-daero, Yeongdeungpo-gu, Seoul, Republic of Korea',
                name1: 'Name of representative: ',
                name2: '이호천 (David Lee)',
                phone1: 'Contact: ',
                phone2: '01068571728',
                phoneInfo: '(Should you have any inquiries, please do not hesitate to contact us via email.)',
                email1: 'Email: ',
                email2: `customer{'@'}i.cool`,
            },
            languagePopup: {
                en_US: 'English',
                ko_KR: '한국어',
            },
            menuPopup: {
                en_US: 'English',
                ko_KR: '한국어',
                home: 'Home',
                wallet: 'Wallet',
                isArtist: 'Artist',
                cart: 'Cart',
                order: 'Order',
                shop: 'Shop',
                featuredCreative: 'Featured Creative',
                customize: 'Customize',
                like: 'Like',
                notice: 'Notice',
                setUp: 'Set Up',
                phoneCase: 'Phone Case',
                logout: 'Logout',
                signUp: 'Sign Up',
                login: 'Login',
                notHaveAccount: 'Not have an account yet?',
                event: 'Event',
            },
            newShareDialog: {
                shareToThirdParties: 'Share to third parties',
                shareSuccess: 'Share success',
            },
            noMore: {
                noData: 'Nothing yet',
                back: 'Back',
            },
            noMoreComment: {
                noData: 'Nothing yet',
                back: 'Back',
            },
            noMoreList: {
                noData: 'Nothing yet',
            },
            noticeBar: {
                clickToView: 'SHOP',
                useIt: 'USE NOW',
            },
            popup: {
                confirm: 'confirm',
            },
            tel: {
                tel: 'Tel',
            },
            empty: {
                noData: 'Nothing yet',
            },
            uploadImg: {
                onlyAcceptImg: 'Only images are allowed.',
                onlyAcceptPng: 'Only PNG and JPEG images are allowed.',
            },
        },
        card: {
            listBox: {
                back: 'Back',
                unbind: 'Unbind',
                unbundleBankCard: 'Unbundle bank card',
                unbundleBankCardContent: 'Are you sure you want to unbind the card?',
                unbindSuccess: 'Unbind successfully',
            },
        },
        cart: {
            empty: {
                shoppingEmpty: 'The shopping cart is empty, so go ahead and add your favorite items!',
                shop: 'Shop',
            },
            itemBox: {
                expired: 'Expired',
                delete: 'Delete',
            },
            paymentBox: {
                placeOrder: 'Place order',
                addAddress: 'Add Address',
                orderPayment: 'Order/Payment',
                shoppingAddress: 'Shopping Address',
                addNotesOptional: 'Add notes（optional）',
                orderDetails: 'Order Details',
                orderTotal: 'Order Total',
                methodPayment: 'Method of payment',
                edit: 'Edit',
                otherPaymentMethods: 'Other payment methods',
                enterPromoCode: 'Enter Promo Code',
                validate: 'Validate',
                availableBalance: 'Available Balance',
                deduction: 'Deduction',
                availableBalanceTip: 'Insufficient available balance, combination payment required',
                redemptionSuccessful: 'Redemption successful',
                invalidPromoCodeInfo: 'This promo code is not valid, please check and try again',
                paySuccess: 'Payment successful',
            },
            paymentGoodItemBox: {
                expired: 'Expired',
            },
        },
        collects: {
            creativeItemBox: {
                expired: 'Expired',
                creativeExpired: 'The creative content has expired',
                likeSuccess: 'The addition is successful, and you can check it in "My-My Likes"~',
                likeCancel: 'Cancelled successful',
            },
            delBox: {
                delReminders: 'Reminders',
                delRemindersContent: 'Are you sure you want to cancel this {0} product?',
                confirm: 'Confirm',
                cancel: 'Cancel',
            },
            empty: {
                emptyLike: 'No likes at this time',
                shop: 'Shop',
                creative: 'Creative',
            },
            itemBox: {
                expired: 'Expired',
                goodExpired: 'This product has expired',
                likeSuccess: 'The addition is successful, and you can check it in "My-My Likes"~',
                likeCancel: 'Cancelled successful',
            },
        },
        comments: {
            commentBox: {
                reply: 'Reply',
                auth: 'Author',
                openReply: 'Expand {0} Reply',
                likeRet: 'Successfully added',
            },
            commentDialog: {
                reply: 'Reply',
                send: 'Send',
            },
            commentPopup: {
                comment: 'Comment...',
                textAndComments: 'Text and comments',
            },
            creativeList: {
                loading: 'Loading',
                noMore: 'No more',
                comment: 'Comment',
            },
            evaluatedItemBox: {
                pending: 'Pending',
                rejected: 'Rejected',
            },
            itemBox: {
                evaluate: 'Evaluate',
            },
            ruleBox: {
                rule1: 'Rule',
                evaluationRules: 'Evaluation rules',
                evaluationRulesT1: '1. Evaluating the statute of limitations for tanning.',
                evaluationRulesC1: 'Customers can evaluate their purchases within 60 days of order fulfillment.',
                evaluationRulesT2: '2.the evaluation of the sunshine statement.',
                evaluationRulesC2_1: `1. In order to ensure the objectivity of the evaluation, once the evaluation content is successfully published, I cannot modify or delete it.`,
                evaluationRulesC2_2: `2. If you purchase multiple items of the same product in one order, they will only be evaluated as one item when evaluating.`,
                evaluationRulesC2_3: `3. The evaluation content must not contain link addresses, otherwise it cannot be submitted.`,
                evaluationRulesC2_4: `4. Banned users cannot submit reviews during the ban period and can contact customer service for consultation.`,
                evaluationRulesC2_5: `5. Since you cannot modify or delete the reviewed reviews, please be careful not to involve your or other people’s sensitive personal information when posting reviews.`,
                evaluationRulesC2_6: `6. Hide the evaluation list: If you want to hide the evaluation records in the evaluation center for privacy reasons, you can go to the order list to hide the corresponding order. The evaluation records will be hidden in the to-be-evaluated and evaluated lists simultaneously. Once hidden, they cannot be restored.`,
                evaluationRulesC2_7: `7. Return and exchange evaluation: Return order product evaluation: If there is no evaluation before the return, the order cannot be evaluated after the return; if the evaluation has been completed before the return is completed, the evaluation will be displayed normally after the return. Product evaluation of exchange orders: If there is no evaluation before the exchange, the products of the pre-exchange order can be evaluated after the exchange; regardless of whether the evaluation of the products of the pre-exchange order has been completed, the new order products can be evaluated normally after the exchange; if the products of the pre-exchange order have been evaluated If you also submit a review for the new order products after the exchange, the product reviews of the order before the exchange will not be displayed, and only the product reviews of the order after the exchange will be displayed.`,
                evaluationRulesC2_8: `8. After the evaluation order and evaluation reply are successfully published, in addition to being displayed on the product details page, evaluation center, and personal homepage, they may also be displayed in complete or summary form on the homepage, search, store, and other locations.`,
                evaluationRulesC2_9: `9. Privacy protection: In order to protect user privacy, the evaluation module on the product details page will desensitize and display the asterisk of the user's nickname, such as: Xiao***xi.`,
            },
        },
        cutcase: {
            orderBox: {
                orderDetails: 'Order Details',
                piece: 'Piece',
                piece1: 'Piece',
                placeOrder: 'Place order',
            },
        },
        goods: {
            addNotesBox: {
                back: 'Back',
                addNote: 'Add Note',
                addNotesOptional: 'Add notes（optional）',
                save: 'Save',
            },
            addressBox: {
                back: 'Back',
                shoppingAddress: 'Shopping Address',
                default: 'Default',
                emptyAddress: 'No address information yet',
                addAddress: 'Add address',
            },
            addressEditBox: {
                back: 'Back',
                editAddress: 'Edit Address',
                addAddress: 'Add Address',
                addressFirst: 'Please enter your address first',
                delete: 'Delete',
                receiver: 'Receiver',
                name: 'Name',
                tel: 'Tel',
                area: 'Area',
                addArea: 'Add shipping address',
                address: 'Address',
                enterAddress: 'Enter detailed address',
                defaultAddress: 'Default address',
                save: 'Save',
                addressDelTip: 'Are you sure you want to delete the address?',
                changeSuccess: 'Modified successfully',
                addSuccess: 'Successfully added',
                delSuccess: 'Deletion successful',
            },
            addShopCard: {
                merchandiseSelect: 'Merchandise select',
                addToCart: 'Add to cart',
                total: 'Total:',
                confirm: 'Confirm',
                pleaseChoose: 'Please choose {0}',
                backplane: 'Cover',
                backplaneInfo: 'Cover (*Choose any one)',
            },
            artistOthreProducts: {
                otherList: 'Recommended Products',
                seeAll: 'See all',
                aop: "Artist's Other Products",
            },
            buyBox: {
                addAddress: 'Add Address',
                orderPayment: 'Order/Payment',
                shoppingAddress: 'Shopping Address',
                addNotesOptional: 'Add notes（optional）',
                orderDetails: 'Order Details',
                edit: 'Edit',
                piece: 'Piece',
                piece1: 'Piece',
                expired: 'Expired',
                priceBreakdown: 'Price details',
                methodPayment: 'Method of payment',
                validate: 'Validate',
                enterPromoCode: 'Enter Promo Code',
                availableBalance: 'Available Balance',
                deduction: 'Deduction',
                availableBalanceTip: 'Insufficient available balance, combination payment required',
                otherPaymentMethods: 'Other payment methods',
                placeOrder: 'Place order',
                redemptionSuccessful: 'Redemption successful',
                invalidPromoCodeInfo: 'This promo code is not valid, please check and try again',
                paySuccess: 'Payment successful',
            },
            buyChooseSku: {
                merchandiseSelect: 'Merchandise select',
                total: 'Total:',
                confirm: 'Confirm',
                pleaseChoose: 'Please choose {0}',
            },
            commentBox: {
                auth: 'Author',
                reply: 'Reply',
            },
            creativeGoodsItem: {
                likeSuccess: 'The addition is successful, and you can check it in "My-My Likes"~',
                likeCancel: 'Cancelled successful',
            },
            filters: {
                confirm: 'Confirm',
                showMore: 'Show more',
                showLess: 'Show less',
                series: 'Series',
                artist: 'Artist',
            },
            goodIdsPopup: {
                popupTitle: 'Related Merchandise',
            },
            goodsSortPopup: {
                default: 'Synthesize',
                saleDESC: 'Highest-selling',
                priceDESC: 'Price high to low',
                priceASC: 'Price low to high',
            },
            item: {
                likeSuccess: 'The addition is successful, and you can check it in "My-My Likes"~',
                likeCancel: 'Cancelled successful',
            },
            priceDetailsBox: {
                totalPrice: 'Total commodity price',
                freight: 'Freight',
                freeTime1: 'Limited time',
                freeTime2: 'Free',
                discount: 'Discounts',
                total: 'Total',
            },
            searchPopup: {
                loading: 'Loading',
                noMore: 'No more',
                searchGoods: 'Search goods',
                empty: 'There are no searches for this product.',
            },
            baseFooter: {
                interestsEnjoyed: 'Customer Rights and Interests',
                interestsEnjoyedItem1: 'Quality Guaranteed',
                interestsEnjoyedItem2: 'Free Shipping',
                interestsEnjoyedItem3: 'Secure Payment',
                interestsEnjoyedItem4: '6 Months Warranty',
                availablePaymentMethods: 'Available Payment Methods',
                icoolOffers1: 'iCOOL offers',
                icoolOffers2: '7-day No Questions Asked Return or Exchange and “6-month Product Warranty for selected products purchased',
            },
            modelWishlistPopup: {
                soldOut: 'Sold Out',
                modelWishlist: 'Model Wishlist',
                modelWishlistContent: '*After submitting your model wishlist, there’s a chance your desired model will be added to our collection!',
                apple: 'Apple',
                samsung: 'Samsung',
                other: 'Other',
                pleaseSelect: 'Please select your desired phone model',
                pleaseInout: 'Please enter your desired phone model',
                success: 'We’ve received your feedback. Thank you for your support of iCOOL!',
                submit: 'Submit',
            },
        },
        home: {
            authsBox: {
                fans: 'Fans',
                follow: 'Follow',
                unFollow: 'Unfollow',
                followSuccess: 'Follow Success',
            },
            creativeBox: {
                featuredCreative1: 'Creative',
                seeMore: 'See More',
            },
            creativeCenterList: {
                loading: 'Loading',
                loosing: 'Release to refresh',
                noMore: 'No more',
            },
            creativeGoodsPopupList: {
                popupTitle: 'Related Merchandise',
                likeSuccess: 'The addition is successful, and you can check it in "My-My Likes"~',
                likeCancel: 'Cancelled successful',
            },
            creativeHomeItem: {
                likeSuccess: 'The addition is successful, and you can check it in "My-My Likes"~',
                likeCancel: 'Cancelled successful',
            },
            creativeItem: {
                buyIt: 'Buy it',
                more: 'More',
                collapse: 'Collapse',
                likeSuccess: 'The addition is successful, and you can check it in "My-My Likes"~',
                likeCancel: 'Cancelled successful',
            },
            customBox: {
                customize: 'Customize',
                seeMore: 'See More',
                useIt: 'Use it',
            },
            hotBox: {
                seeMore: 'See More',
            },
            hotItem: {
                likeSuccess: 'The addition is successful, and you can check it in "My-My Likes"~',
                likeCancel: 'Cancelled successful',
            },
            seriesBox: {
                viewThisSeries: 'Buy It Now',
            },
        },
        logistic: {
            itemBox: {
                orderRefundTitle: 'Order Refund and Exchange Information',
                orderNormalTitle: 'Order Logistics Information',
                packageInfo: 'Package Information',
                packageDynamics: 'Package Dynamics',
                refundInfoDynamics: 'Refund Dynamics',
                refundTitleSuccess: 'Your Order Has Been Successfully Refunded',
                refundTitleFaild: 'Your Order Refund Was Unsuccessful',
                refundInfoSuccess: 'Refund Has Been Processed',
                refundInfoFaild: 'Refund Application Was Unsuccessful, Please Address Promptly',
                bvy: 'Purchase',
                replace: 'Exchange',
                view: 'View',
                logistics_level_msg_title1: 'Your {0} item has been shipped',
                logistics_level_msg_title3: 'Your {0} item is in transit',
                logistics_level_msg_title6: 'Your {0} item has been delivered',
            },
        },
        message: {
            comment: {
                loading: 'Loading',
                loosing: 'Release to refresh',
                noMore: 'No more',
                commentTitle: 'Comment list',
                getNewMsg: 'Received new message',
                placePullRefresh: 'Please pull down to refresh',
                auth: 'Author',
            },
            focus: {
                loading: 'Loading',
                loosing: 'Release to refresh',
                noMore: 'No more',
                getNewMsg: 'Received new message',
                placePullRefresh: 'Please pull down to refresh',
                followTitle: 'Follow directory',
                followMe: 'I followed you',
                follow: 'Follow',
                unFollow: 'Unfollow',
                followSuccess: 'Follow Success',
            },
            friend: {
                loading: 'Loading',
                loosing: 'Release to refresh',
                noMore: 'No more',
                getNewMsg: 'Received new message',
                placePullRefresh: 'Please pull down to refresh',
                friendMessageList: 'Friend message list',
                officialNotice: 'official notice',
            },
            friendMsgPopup: {
                loading: 'Loading',
                loosing: 'Release to refresh',
                noMore: 'No more',
                friendMessage1: 'Friend message',
                fans: 'Fans',
                follow: 'Follow',
                followSuccess: 'Follow Success',
            },
            like: {
                loading: 'Loading',
                loosing: 'Release to refresh',
                noMore: 'No more',
                getNewMsg: 'Received new message',
                placePullRefresh: 'Please pull down to refresh',
                starMeTitle: 'Likes and favorites list',
            },
            sysMsgPopup: {
                loading: 'Loading',
                loosing: 'Release to refresh',
                noMore: 'No more',
                officialNotice: 'official notice',
                title: 'iCOOL',
            },
        },
        order: {
            applyAfterSales: {
                back: 'Back',
                submitAfterSales: 'Submit after-sales',
                afterSalesExchange: 'Exchange',
                afterSalesReasons: 'After-sales reasons',
                justRefund: 'Just Refund',
                returnRefund: 'Return Refund',
                placeInput: 'Please enter content',
                placeInput1: 'Please enter content',
                submit: 'submit',
                submitSuccess: 'Submit successfully',
                returnPolicyTips: 'As this platform is a customized product, refunds are not supported for non-product issues, click to view the',
                returnPolicyBtn: 'Return policy',
                exchangeReturnPolicy: 'Exchange/Return Policy',
                timeoutNotShipped: 'Timeout not shipped',
                timeoutNotReceived: 'Timeout not received',
                receivedGoodsDoNotMatch: 'Received goods do not match the description, damaged or defective goods',
                selectAfterSaleItems: 'Please select after-sale items',
            },
            confirmOrder: {
                piece: 'Piece',
                toBePaid: 'To be paid: ',
                actuallyPaid: 'Actually paid: ',
                payable: 'Payable: ',
                confirmReceipt: 'Confirm receipt',
                confirmReceiptInfo: 'Have you confirmed receipt of the product? ',
                confirmReceiptSuccess: 'Confirm receipt of goods successfully',
                confirm: 'confirm',
                cancel: 'cancel',
            },
            delOrder: {
                piece: 'Piece',
                toBePaid: 'To be paid: ',
                actuallyPaid: 'Actually paid: ',
                payable: 'Payable: ',
                deleteOrder: 'Delete order',
                deleteReminderTitle: 'Delete reminder',
                deleteReminderContent: 'Are you sure you want to delete this order?',
                cancel: 'cancel',
                delSuccess: 'Deletion successful',
            },
            detailDelOrder: {
                piece: 'Piece',
                toBePaid: 'To be paid: ',
                actuallyPaid: 'Actually paid: ',
                payable: 'Payable: ',
                deleteOrder: 'Delete order',
                deleteReminderTitle: 'Delete reminder',
                deleteReminderContent: 'Are you sure you want to delete this order?',
                cancel: 'cancel',
                delSuccess: 'Deletion successful',
            },
            itemBox: {
                orderStatus: {
                    1: 'Pending payment',
                    2: 'To be shipped',
                    3: 'On the Way',
                    4: 'To be evaluated',
                    5: 'Completed',
                    6: 'Cancelled',
                    7: 'After sale',
                    8: 'To be shipped',
                },
                afterSaleStatus: {
                    '-1': 'User cancels',
                    1: 'Pending',
                    2: 'To be shipped',
                    3: 'On the Way',
                    4: 'Completed',
                    5: 'Audit rejection',
                    6: 'Pending refund',
                    7: 'Refunding',
                },
                piece: 'Piece',
                total: 'Total:',
                viewLogistics: 'Logistics',
                confirmReceipt: 'Confirm receipt',
                applyAfterSales: 'Apply',
                afterSalesProgress: 'Progress',
                deleteOrder: 'Delete order',
                payment: 'Payment',
                afterSales: 'After-sales',
                view: 'View',
            },
            lookAfterSales: {
                back: 'Back',
                afterSaleEventStatus: {
                    RefundSucc: 'Refund successful',
                    RefundFail: 'Refund failed',
                    Refunding: 'Refunding',
                    ReplaceGoodsSucc: 'Replacement completed',
                    ReplaceGoodsFail: 'Replacement failed',
                    ReplaceGoodsing: 'Replacement in progress',
                    RequestAfterSale: 'Submit for review',
                    WaitReview: 'Waiting for review',
                    ReviewResolve: 'review passed',
                    ReviewReject: 'Review rejection',
                    WaitRefund: 'Waiting for refund',
                    WaitShip: 'Waiting for shipment',
                    WaitReceiveGoods: 'Waiting for receipt',
                    Completed: 'completed',
                    UserCancel: 'User Cancel',
                },
                refundSuccTxt: 'The iCOOL platform has processed your refund, which is expected to be credited within 7 business days.',
            },
            orderItemInfo: {
                piece: 'Piece',
            },
        },
        originality: {
            card: {
                follow: 'Follow',
                unFollow: 'Unfollow',
                dontFollow: `Sure you don't want to continue?`,
                followSuccess: 'Follow Success',
            },
            detailGoodItem: {
                likeSuccess: 'The addition is successful, and you can check it in "My-My Likes"~',
                likeCancel: 'Cancelled successful',
            },
            goods: {
                mall: 'Recommended Goods',
            },
            itineraryList: {
                itinerary: 'Itinerary',
                dateInfo: 'Exhibition date',
            },
            story: {
                storyTitle: 'Creative Story',
            },
        },
        plays: {
            c: {
                material: {
                    background: {
                        back: 'Back',
                        next: 'Next',
                    },
                    collectionList: {
                        loading: 'Loading',
                        loosing: 'Release to refresh',
                        noMore: 'No more',
                        emptyInfo: 'There is currently no related material library',
                    },
                    delMaterialBox: {
                        delMaterialTitle: 'Reminders',
                        delMaterialContent: 'Are you sure you want to delete the image?',
                        confirm: 'Confirm',
                        cancel: 'Cancel',
                    },
                    index: {
                        back: 'Back',
                        myCollection: 'My Collection',
                        myUploads: 'My Uploads',
                        officialSelection: 'Official Selection',
                    },
                    offerListItem: {
                        loading: 'Loading',
                        loosing: 'Release to refresh',
                        noMore: 'No more',
                        emptyInfo: 'There is currently no related material library',
                    },
                    uploadList: {
                        loading: 'Loading',
                        loosing: 'Release to refresh',
                        noMore: 'No more',
                        emptyInfo: 'There is currently no related material library',
                        uploadImage: 'Upload Image',
                    },
                },
                loadImg: {
                    generationStep1: 'Image/text parsing',
                    generationStep2: 'Matching AI parameters',
                    generationStep3: 'Color filling',
                },
                useAiStyle: {
                    back: 'Back',
                    useIt: 'Use it',
                },
                usePattern: {
                    copies: 'Copies',
                    type: 'Type',
                },
                useTxtInput: {
                    firstGenerate: 'Generate',
                    descrihatPlaceHolder: 'For example: a blue-eyed cat, playing with a ball of wool',
                },
                cake: {
                    back: 'Back',
                    confirm: 'Confirm',
                },
                lens: {
                    back: 'Back',
                    confirm: 'Confirm',
                },
            },
            config: {
                componentsConfig: {
                    play31Placeholder: 'eg：Rose、Tulip、Daisy、Sunflower、Lotus、Orchid',
                    play31ConfirmText: 'Next',
                    play34Placeholder: 'eg：Ice cream、Sunglasses、Sunhat、Flip flops、ice、juice',
                    play34ConfirmText: 'Next',
                    play32Placeholder: 'eg：Mango、Candy、Vanilla、Strawberry',
                    play32ConfirmText: 'Next',
                    play43Placeholder: 'Enter Something...(Only 3 words)',
                },
            },
            fabric: {
                base: {
                    text: {
                        control: {
                            font: 'Font',
                            color: 'Color',
                            keyboard: 'Keyboard',
                        },
                        editTextModal: {
                            clickToEdit: 'Click to enter',
                            play7TxtInfo: 'Only letters are allowed',
                        },
                    },
                    layerControl: {
                        forward: 'Forward',
                        backward: 'Backward',
                        horizontal: 'Horizontal',
                        vertical: 'Vertical',
                    },
                },
                textFontPopup: {
                    fontTab: 'Font',
                    strokeTab: 'Stroke',
                    none: 'None',
                    black: 'Black',
                    white: 'White',
                    green: 'Green',
                    purple: 'Purple',
                    alpha: 'Alpha',
                    width: 'Thickness',
                },
            },
            cutPhoneCase: {
                back: 'Back',
                phoneCase: 'Phone case',
                uploadImage: 'Upload image',
                uploadImageInfo: '*Please upload photos.',
                uploadImageInfo1: '*Please upload clear portrait photos.',
                uploadImageInfoAccept: '*Please upload JPEG or PNG images.',
                portraitOnly: 'portrait only',
                uploadTxt: 'Click to upload',
                next: 'Next',
                font: 'Font',
                noImgTipTitle: 'Tips',
                noImgTipInfo: 'To ensure quality, please place the image in the operating area',
                noImgTipConfirm: 'I see',
                playsError: 'There is an error in the gameplay. This gameplay is not supported for the time being.',
                play22Placeholder: 'Enter Something...({0} words or less)',
                play42Info: '*Two photos will be shown front and back',
                play42Info1: '',
                play48Info: '*Please upload two images, no commercial',
                play48Info1: 'images are allowed,no larger than 1M.',
                play48ImgTip1: 'Favorite Style',
                play48ImgTip2: 'To be shifted in style',
            },
            drawPhoneCase: {
                back: 'Back',
                generationFailed: 'Generation failed',
                case: 'Case',
                upload: 'Upload',
                style: 'Style',
                library: 'Library',
                font: 'Font',
                cutout: 'Cutout',
                useText: 'Use text',
                pattern: 'Pattern',
                stencil: 'Stencil',
                graffiti: 'Graffiti',
                background: 'Background',
                keyboard: 'Keyboard',
                save: 'Save',
                noImgTipConfirm: 'I see',
                noImgTipTitle: 'Tips',
                quitTipInfo: 'Are you sure you want to close it? If you close it, the current operation will not be saved',
                cutTipTitle: 'Cutout tips',
                cutTipInfo: 'To keep matting, select an image with a clear edge and a body',
                noTip: 'No more prompts',
                cake: 'Flavor',
                again: 'Again',
                lens: 'Fisheye lens',
            },
            prePhoneCase: {
                back: 'Back',
                generationFailed: 'Generation failed',
                case: 'Case',
                upload: 'Upload',
                style: 'Style',
                library: 'Library',
                font: 'Font',
                cutout: 'Cutout',
                useText: 'Use text',
                pattern: 'Pattern',
                stencil: 'Stencil',
                graffiti: 'Graffiti',
                background: 'Background',
                keyboard: 'Keyboard',
                save: 'Save',
                noImgTipConfirm: 'I see',
                noImgTipTitle: 'Tips',
                quitTipInfo: 'Are you sure you want to close it? If you close it, the current operation will not be saved',
                cutTipTitle: 'Cutout tips',
                cutTipInfo: 'To keep matting, select an image with a clear edge and a body',
                noTip: 'No more prompts',
                cake: 'Flavor',
                again: 'Again',
                lens: 'Fisheye lens',
            },
            selectPhone: {
                back: 'Back',
                phoneCase: 'iPhone Case',
                filter: 'Filter',
                next: 'Next',
                confirm: 'Confirm',
            },
            lens: {
                back: 'Back',
                next: 'Next',
            },
        },
        user: {
            historyNoMorePage: {
                loading: 'Loading',
                noMore: 'No more',
                recommendForYou: 'Recommend for you',
            },
        },
        withdraw: {
            noAuthBox: {
                withdreawalTips: 'Withdrawal tips',
                withdreawalTipsContent: 'To ensure the security of your account, please bind your mobile phone number or email address and successfully verify it before you can withdraw money.',
                withdreawalTipsConfirm: 'Go to binding',
                cancel: 'Cancel',
            },
            verificationBox: {
                back: 'Back',
                verificationIdentity: 'Verification of identity',
                verificationCode: 'Verification code',
                getVerificationCode: 'Get verification code',
                emailAuthentication: 'Email authentication',
                telAuthentication: 'Tel authentication',
                confirm: 'Confirm',
            },
        },
    },
    pages: {
        author: {
            expand: '\nExpand',
            collapse: '\nCollapse',
            fans: 'Fans',
            followers: 'Followers',
            follow: 'Follow',
            unFollow: 'Following',
            product: 'Product',
            loading: 'Loading',
            noMore: 'No more',
            empty: 'No related Data',
            emptyWork: 'No related works',
            dontFollow: `Sure you don't want to continue?`,
            followSuccess: 'Follow Success',
            artwork: 'Artwork',
            artworkNum: 'Artist · {0} Artworks',
            ipNum: 'Characters',
            productsNum: 'Products',
            viewMore: 'Learn More',
            showLess: 'Show Less',
            creatorStory: 'Artist Story',
        },
        cart: {
            cart: 'Cart',
            addAddress: 'Add address',
            done: 'Done',
            manage: 'Manage',
            loading: 'Loading',
            noMore: 'No more',
            recommendForYou: 'Recommend for you',
            all: 'All',
            selected: 'Selected {0}',
            delete: 'Delete',
            placeOrder: 'Place order',
            delSku: 'Are you sure you want to delete these {0} products?',
            noSelect: "You haven't selected any products yet",
            delSuccess: 'Deletion successful',
            total: 'Total:',
        },
        creative: {
            video: {
                empty: 'This creative content is no longer valid',
                more: 'More',
                collapse: 'Collapse',
                share: 'share',
                buyIt: 'Buy it',
                likeCancel: 'Cancelled successful',
                likeSuccess: 'The addition is successful, and you can check it in "My-My Likes"~',
            },
            article: {
                empty: 'This creative content is no longer valid',
                share: 'share',
                likeCancel: 'Cancelled successful',
                likeSuccess: 'The addition is successful, and you can check it in "My-My Likes"~',
                buyIt: 'Buy it',
            },
        },
        goods: {
            detail: {
                addedToCart: 'Added to cart',
                details: 'Details',
                by: 'By',
                review: 'Review',
                loading: 'Loading',
                comment: 'Review',
                default: 'Synthesize',
                newest: 'Newest',
                seeAllComments: 'See all comments',
                likeCancel: 'Cancelled successful',
                likeSuccess: 'The addition is successful, and you can check it in "My-My Likes"~',
                cart: 'Cart',
                purchase: 'Purchase',
                tagv1: 'First order: 30-day free return/exchange',
                tagv2: 'Made in Korea',
                tagv3: 'Free Shipping',
                tagv4: 'For non-pre-sale items, orders placed Monday to Friday will arrive in 2 days; orders placed from Friday to Sunday will arrive in 3-4 days',
                stock: '{0} in stock',
                shipsWithin: 'Ships within {0} days',
                reviews: '{0} reviews',
                community: 'Community',
                youLike: 'You may also like',
                customized: 'Custom design',
            },
            allcomment: {
                comment: 'Comment',
                ealuationYet: 'There are no reviews yet',
                default: 'Synthesize',
                newest: 'Newest',
                loading: 'Loading',
                noMore: 'No more',
            },
            mall: {
                searchGoods: 'Search goods',
                phoneCase: 'Phone Case',
                filters: 'Filters',
                loading: 'Loading',
                loosing: 'Release to refresh',
                noMore: 'No more',
                default: 'Synthesize',
                saleDESC: 'Highest-selling',
                saleASC: 'Ascending order of sales',
                priceDESC: 'Price high to low',
                priceASC: 'Price low to high',
            },
            fail: {
                payFail: 'Payment failed',
                backHome: 'Back Home',
                viewOrder: 'View order',
            },
            result: {
                paySuccess: 'Payment successful',
                paySuccessInfo1: 'Cool products are being customized, we will arrange delivery as soon as possible',
                backToShop: 'Back to shop',
                viewOrder: 'View order',
                payFail: 'Payment failed',
                insTips1: `After purchasing a phone case from the iCOOL website, please visit Instagram {'@'}`,
                insTips2: 'icool.kr',
                insTips3: ' and fill out the form linked in our bio to enter the giveaway! ',
                insTips4: 'We will be raffling off props used by ZB1 in their ad!',
                insBtn: 'Go Now',
            },
            item: {
                tagv1: 'Free Shipping',
                tagv2: 'Made in Korea',
                tagv3: '2-Work-Day Delivery',
            },
        },
        originality: {
            loading: 'Loading',
            noMore: 'No more',
            empty: 'No related Data',
            all: 'All',
            detail: {
                artist: 'Artist',
                designer: 'designer',
                follow: 'Follow',
                unFollow: 'Unfollow',
                coCreativeDesigner: 'Co-Creative Designer',
                loading: 'Loading',
                noMore: 'No more',
                dontFollow: `Sure you don't want to continue?`,
                followSuccess: 'Follow Success',
            },
        },
        plays: {
            list: {
                customizeCenter: 'Custom Center',
                customizeTemp: 'Custom templates',
                loading: 'Loading',
                loosing: 'Release to refresh',
                noMore: 'No more',
                useIt: 'Use it',
                all: 'All',
            },
            mbti: {
                //未上线
                phoneCase: 'Phone Case',
                next: 'Next',
                title: '*Please select the MBTI role.',
            },
            cake: {
                //未上线
                phoneCase: 'Phone Case',
                next: 'Next',
                title: '*Please choose your favorite cake flavor.',
            },
        },
        series: {
            artist: 'Artist',
            designer: 'designer',
            follow: 'Follow',
            unFollow: 'Unfollow',
            coCreativeDesigner: 'Co-Creative Designer',
            series: 'Series',
            loading: 'Loading',
            noMore: 'No more',
            cancel: 'Cancel',
            confirm: 'Confirm',
            followSuccess: 'Follow Success',
            dontFollow: `Sure you don't want to continue?`,
        },
        user: {
            browser: {
                browserRecord: 'Browsing history',
                manage: 'Manage',
                done: 'Done',
                merchandise: 'Merchandise{0}',
                creative: 'Creative{0}',
                noHistory: 'No browsing history yet',
                shopNow: 'Shop Now',
                loading: 'Loading',
                noMore: 'No more',
                expired: 'Expired',
                all: 'All',
                delete: 'Delete',
                selected: 'Selected {0}',
                today: 'Today',
                noSelectGoods: `You haven't selected any goods yet`,
                noSelectCreative: `You haven't selected any creative yet`,
                delSuccess: 'Deletion successful',
            },
            commentSuccess: {
                loading: 'Loading',
                noMoreProductsEvaluated: 'No more products to be evaluated~',
                evaluationCompleted: 'Evaluation completed',
                evaluationCompleted1: 'Evaluation was successful, thank you very much!',
                view: 'View',
                keepTheReviewsComing: 'Keep the reviews coming',
                evaluate: 'Evaluate',
            },
            comment: {
                productScore: 'Product evaluation',
                wordsEntered1: '',
                wordsEntered2: 'words entered',
                placeholderTxt: 'Call up the keyboard and write a more helpful review!',
                addPhoto: 'Add photo',
                surolusNum: '{0} more can be uploaded',
                remarkTxt: 'Reviews will be displayed anonymously',
                publish: 'Publish',
                star1: 'Very bad',
                star2: 'Poor',
                star3: 'General',
                star4: 'Good',
                star5: 'Very good',
                placeInputTxt: 'Please fill in the evaluation content',
                evaluationSuccess: 'Evaluation successful',
            },
            follow: {
                addFollow: 'Add Friends',
                inputPlaceholder: 'Say something...',
                recommended: 'Recommended to you',
            },
            goodExpired: {
                productExpired: 'This product has expired',
                goToMall: 'Go to the mall',
            },
            balance: {
                walletBalance: 'Wallet Balance',
                totalBalance: 'Total Balance',
                bankCard: 'Bank card({0})',
                withdraw: 'Withdraw',
                bill: 'Bill',
                noMore: 'No more',
                all: 'All',
            },
            bill: {
                details: 'Details',
                withdrawTime: 'Withdraw time',
                arrivalTime: 'Arrival time',
                card: 'Card',
                withdrawType: 'Withdraw type',
                withdrawID: 'Withdraw ID',
                transactionType: 'Transaction type',
                orderAmount: 'Order amount',
                coolProductPricing: 'Cool product pricing',
                orderID: 'Order ID',
            },
            card: {
                add: {
                    addBankCard: 'Add bank card',
                    submit: 'Submit',
                },
                list: {
                    name: 'Bank Card',
                    unbind: 'Unbind',
                    noBankCard: 'There is currently no bank card',
                    addBankCard: 'Add bank card',
                },
            },
            collects: {
                like: 'Like',
                loading: 'Loading',
                noMore: 'No more',
                merchandise: 'Merchandise{0}',
                creative: 'Creative{0}',
                recommendForYou: 'Recommend for you',
                all: 'All',
                selected: 'Selected {0}',
                delete: 'Delete',
                noSelectCreative: `You haven't selected any creative yet`,
                noSelectGoods: `You haven't selected any goods yet`,
                delRemindersContent: 'Are you sure you want to delete this {0} creative? ',
                delGoodsRemindersContent: 'Are you sure you want to delete this {0} product? ',
                canceledSuccessfully: 'Canceled successfully',
            },
            comments: {
                beEvaluatedValue: 'Be evaluated · {0}',
                evaluated: 'Evaluated',
                rule1: 'Rule',
                loading: 'Loading',
                noMore: 'No more products to be evaluated~',
                noMore1: 'All historical reviews have been loaded for you~',
            },
            logistic: {
                loosing: 'Release to refresh',
                loading: 'Loading',
                noMore: 'No more',
                emptyMsg: 'Currently there is no logistics notification',
            },
            order: {
                detail: {
                    orderStatus: {
                        1: 'Pending payment',
                        2: 'To be shipped',
                        3: 'On the Way',
                        4: 'To be evaluated',
                        5: 'Completed',
                        6: 'Cancelled',
                        7: 'After sale',
                        8: 'To be shipped',
                    },
                    piece: 'Piece',
                    afterSalesProgress: 'Progress',
                    productPrice: 'Product price',
                    freight: 'Freight',
                    freeTime1: 'Limited time',
                    freeTime2: 'Free',
                    balance: 'Balance',
                    discount: 'Discounts',
                    invoice: 'invoice',
                    invoiceInfo: 'Not supported',
                    totalPrice: 'Total amount payable',
                    orderTime: 'Order time',
                    orderNo: 'Order No',
                    paymentMethod: 'Payment method',
                    couponCode: 'Coupon Code',
                    shipmentNum: 'Shipment Num',
                    deleteOrder: 'Delete order',
                    payment: 'Payment',
                    viewLogistics: 'Logistics',
                    confirmReceipt: 'Confirm receipt',
                    applyAfterSales: 'Apply',
                    deleteReminderTitle: 'Delete reminder',
                    deleteReminderContent: 'Are you sure you want to delete this order?',
                    confirmReceiptInfo: 'Have you confirmed receipt of the product? ',
                    afterSaleEventStatus: {
                        RefundSucc: 'Refund successful',
                        RefundFail: 'Refund failed',
                        Refunding: 'Refunding',
                        ReplaceGoodsSucc: 'Replacement completed',
                        ReplaceGoodsFail: 'Replacement failed',
                        ReplaceGoodsing: 'Replacement in progress',
                        RequestAfterSale: 'Submit for review',
                        WaitReview: 'Waiting for review',
                        ReviewResolve: 'review passed',
                        ReviewReject: 'Review rejection',
                        WaitRefund: 'Waiting for refund',
                        WaitShip: 'Waiting for shipment',
                        WaitReceiveGoods: 'Waiting for receipt',
                        Completed: 'completed',
                        UserCancel: 'User Cancel',
                    },
                    copySuccess: 'Copy successful',
                    payType1: 'Balance + Third-party Payment',
                    payType1_1: 'Balance + Card Payment',
                    payType1_2: 'Balance+ KakaoPay',
                    payType1_3: 'Balance + NaverPay',
                    payType1_5: 'Balance + Payco',
                    payType1_6: 'Balance + Toss',
                    payType2: 'Third-party Payment',
                    payType3: 'Discount Code Payment',
                    payType4: 'Balance Payment',
                    payType2_1: 'Card Payment',
                    payType2_2: 'KakaoPay',
                    payType2_3: 'NaverPay',
                    payType2_5: 'Payco',
                    payType2_6: 'Toss',
                    payType5: 'Discount Code + Balance Payment',
                    payType6: 'Discount Code + Balance + Third-party Payment',
                    payType6_1: 'Discount Code + Balance + Card Payment',
                    payType6_2: 'Discount Code + Balance + KakaoPay',
                    payType6_3: 'Discount Code + Balance + NaverPay',
                    payType6_5: 'Discount Code + Balance + Payco',
                    payType6_6: 'Discount Code + Balance + Toss',
                    payType7: 'Discount Code + Third-party Payment',
                    payType7_1: 'Discount Code + Card Payment',
                    payType7_2: 'Discount Code + KakaoPay',
                    payType7_3: 'Discount Code + NaverPay',
                    payType7_5: 'Discount Code + Payco',
                    payType7_6: 'Discount Code + Toss',
                    confirmReceiptSuccess: 'Confirm receipt of goods successfully',
                    delSuccess: 'Deletion successful',
                },
                list: {
                    searchInfo: 'Search orders',
                    all: 'All',
                    orderStatus: {
                        1: 'Pending payment',
                        2: 'To be shipped',
                        3: 'On the Way',
                        4: 'To be evaluated',
                        5: 'Completed',
                        6: 'Cancelled',
                        7: 'After sale',
                        8: 'To be shipped',
                    },
                    loading: 'Loading',
                    noMore: 'No more',
                    orderNoMoreinfo: 'There are currently no related orders',
                    deleteOrder: 'Delete order',
                    deleteReminderTitle: 'Delete reminder',
                    deleteReminderContent: 'Are you sure you want to delete this order?',
                    confirmReceipt: 'Confirm receipt',
                    confirmReceiptInfo: 'Have you confirmed receipt of the product? ',
                    delSuccess: 'Deletion successful',
                    confirmReceiptSuccess: 'Confirm receipt of goods successfully',
                },
                logistic: {
                    piece: 'Piece',
                    viewAllOrders: 'View all orders',
                    logistics_1: 'Goods received',
                    logistics_2: 'Goods in transit',
                    logistics_3: 'Destination arrived',
                    logistics_4: 'Departed for delivery',
                    logistics_5: 'Delivery completed',
                    orderTime: 'Order time',
                    orderNo: 'Order No',
                    paymentMethod: 'Payment method',
                    shipmentNum: 'Shipment Num',
                    copySuccess: 'Copy successful',
                    payType1: 'Balance + Third-party Payment',
                    payType1_1: 'Balance + Card Payment',
                    payType1_2: 'Balance+ KakaoPay',
                    payType1_3: 'Balance + NaverPay',
                    payType1_5: 'Balance + Payco',
                    payType1_6: 'Balance + Toss',
                    payType2: 'Third-party Payment',
                    payType3: 'Discount Code Payment',
                    payType4: 'Balance Payment',
                    payType2_1: 'Card Payment',
                    payType2_2: 'KakaoPay',
                    payType2_3: 'NaverPay',
                    payType2_5: 'Payco',
                    payType2_6: 'Toss',
                    payType5: 'Discount Code + Balance Payment',
                    payType6: 'Discount Code + Balance + Third-party Payment',
                    payType6_1: 'Discount Code + Balance + Card Payment',
                    payType6_2: 'Discount Code + Balance + KakaoPay',
                    payType6_3: 'Discount Code + Balance + NaverPay',
                    payType6_5: 'Discount Code + Balance + Payco',
                    payType6_6: 'Discount Code + Balance + Toss',
                    payType7: 'Discount Code + Third-party Payment',
                    payType7_1: 'Discount Code + Card Payment',
                    payType7_2: 'Discount Code + KakaoPay',
                    payType7_3: 'Discount Code + NaverPay',
                    payType7_5: 'Discount Code + Payco',
                    payType7_6: 'Discount Code + Toss',
                },
            },
            coupons: {
                coupons: 'Coupons',
                loading: 'Loading',
                noMore: 'No more',
                off: 'OFF',
                validityPeriod: 'Valid until:',
                emptyMsg: 'No coupons available at this time',
                couponCode: 'Coupon Code:',
            },
        },
        userInfo: {
            title: 'Mine',
            more: 'More',
            collapse: 'Collapse',
            fans: 'Fans',
            followers: 'Followers',
            isArtist: 'Artist',
            wallet: 'Wallet',
            cart: 'Cart',
            order: 'Order',
            comment: 'Comment',
            like: 'Like',
            artWorks: 'ArtWork',
            viewMore: 'View all',
            notice: 'Notice',
            history: 'Browse History',
            setUp: 'Set Up',
            artworkList: {
                loading: 'Loading',
                noMore: 'No more',
            },
            coupons: 'Coupons',
            following: {
                fans: 'Fans',
                followers: 'Followers',
                noOneFans: 'No one is following you yet',
                noOneOtherFans: 'No one is following this person yet',
                noOneFollowers: 'No followers yet',
                noOneOtherFollowers: 'No followers yet',
                loading: 'Loading',
                follow: 'Follow',
                following: 'Following',
                dontFollow: `Sure you don't want to continue?`,
                followSuccess: 'Follow Success',
            },
        },
        withdraw: {
            bankCard: 'Bank card({0})',
            addBankCard: 'Add bank card',
            withdrawalAmount: 'Withdrawal Amount',
            minimumWithdrawal: 'Minimum withdrawal limit',
            availableBalance: 'Available Balance',
            all: 'All',
            withdraw: 'Withdraw',
            withdreawalTips: 'Withdrawal tips',
            withdreawalTipsContent: 'To ensure the security of your account, please bind your mobile phone number or email address and successfully verify it before you can withdraw money.',
            withdreawalTipsConfirm: 'Go to binding',
            result: {
                withdrawSuccessfully: 'Withdraw successfully',
                withdrawSuccessfullyContent: 'The withdrawal has been submitted for review, please wait patiently.',
                details: 'Details',
                backWallet: 'Back to wallet',
            },
            resultError: {
                withdrawalFailed: 'Withdrawal failed',
                withdrawalFailedDis: 'Withdrawal failed, has been rolled back to wallet balance',
                back: 'Back',
            },
        },
        404: {
            404: 'Your page not found, please click to reload',
            back: 'Back',
        },
        aihistory: {
            history: 'History',
            loading: 'Loading',
            loosing: 'Release to refresh',
            noMore: 'No more',
            hisEmptyInfo: 'Currently there are no records',
            collectRet: 'Collection successful',
            collectCancel: 'Cancel favorites',
        },
        appeal: {
            appealTitle: 'Appeal',
            appealDes: 'Description of appeal content',
            placeInput: 'Please enter content',
            appealUpload: 'Upload evidence',
            submit: 'submit',
            submitSuccess: 'Submit successfully',
        },
        index: {
            phoneCase: 'Phone Case',
            coLabs: 'All Character Co-Labs',
        },
        login: {
            tel: 'Tel',
            telErrTip: 'Please enter the correct mobile phone number',
            email: 'Email',
            emailErrTip: 'Please enter a valid email',
            verificationCode: 'Verification code',
            getVerificationCode: 'Get verification code',
            pwd: 'Password',
            pwdErrTip: 'Password length limit is 6 to 20 characters',
            telLogin: 'Tel login',
            emailLogin: 'Email login',
            pwdLogin: 'Password login',
            verificationCodeLogin: 'Verification code login',
            simpleLogin: 'Simple login',
            login: 'Login',
            signUp: 'Sign up',
            phoneNumberVerification: 'Phone number verification',
            emailVerification: 'Email verification',
            passwordsNitMatch: 'Passwords Do Not Match',
            confirmPwd: 'Confirm Password',
            userPrivacyAlerts: 'User Privacy Alerts',
            agreement1: `By signing in you agree to our`,
            agreement2: ` “Privacy agreement”`,
            agreement3: ` and`,
            agreement4: ` “Terms of Service”`,
            dialogAgreement: 'Please read and agree to the following',
            dialogAgree: 'Agree and continue',
            userAgreement: 'Terms of Service',
            privacyAgreement: 'Privacy agreement',
        },
        me: {
            mine: 'Mine',
            fans: 'Fans',
            follow1: 'follow',
            integral: 'points',
            myWallet: 'My Wallet',
            cart: 'Cart',
            commodityCollects: 'Commodity collects',
            browsingHistory: 'Browsing history',
            beEvaluated: 'Be evaluated',
            myOrder: 'My order',
            all: 'All',
            orderStatus: {
                1: 'Pending payment',
                2: 'To be shipped',
                3: 'On the Way',
                4: 'To be evaluated',
                5: 'Completed',
                6: 'Cancelled',
                7: 'After sale',
                8: 'To be shipped',
            },
            loading: 'Loading',
            loosing: 'Release to refresh',
            noMore: 'No more',
            orderNoMoreinfo: 'There are currently no related orders',
        },
        message: {
            message: 'Message',
            getMessage: '{0} messages',
            friendMessage: 'Friend',
            followMessage: 'Follow',
            likeMessage: 'Like and favorite',
            commentMessage: 'Comment',
            clearRead: 'Become read',
        },
        modifyEmail: {
            modifyEmail: 'Modify email',
            emailBinding: 'Email binding',
            emailAuthentication: 'Email authentication',
            telAuthentication: 'Tel authentication',
            verificationCode: 'Verification code',
            getVerificationCode: 'Get verification code',
            newEmail: 'New email',
            email: 'Email',
            emailErrTip: 'Please enter a valid email',
            confirm: 'Confirm',
        },
        modifypwd: {
            setPwd: 'Set password',
            modifyPwd: 'Change password',
            emailAuthentication: 'Email authentication',
            telAuthentication: 'Tel authentication',
            verificationCode: 'Verification code',
            getVerificationCode: 'Get verification code',
            pwdErrTip: 'Password length limit is 6 to 20 characters',
            pwd: 'Password',
            confirmPwd: 'Confirm Password',
            confirm: 'Confirm',
        },
        modifytel: {
            emailAuthentication: 'Email authentication',
            telAuthentication: 'Tel authentication',
            verificationCode: 'Verification code',
            getVerificationCode: 'Get verification code',
            telBinding: 'Tel binding',
            modifyTel: 'Modify tel',
            verificationCode1: 'Verification code',
            getVerificationCode1: 'Get verification code',
            tel: 'Tel',
            newTel: 'New tel',
            confirm: 'Confirm',
            telErrTip: 'Please enter the correct mobile phone number',
        },
        points: {
            details: 'Details',
            pointsAvailable: 'Points Available',
            memberTasks: 'Member Tasks',
            register: 'Register',
            completeRegistration: 'Complete registration',
            completed: 'Completed',
            comingSoon: 'Coming soon',
            completeMaterial: 'Complete material',
            completePersonalInformation: 'Complete personal information',
            levelUp: 'Level Up',
            becomeLevelMember: 'Become member',
            dailyLogin: 'Daily login',
            dailyLoginC: 'Log in to get points, remember to check out iCOOL often',
            share: 'Share',
            shareC: 'Share cool items/works and get rewards',
        },
        profileedit: {
            man: 'Man',
            woman: 'Woman',
            username: 'Username',
            signature: 'Signature',
            hobby: 'Hobby',
            confirm: 'Confirm',
        },
        setting: {
            basicSettings: 'Basic settings',
            email1: 'Email',
            loginPwd: 'Login password',
            tel: 'Tel',
            noSetting: 'Not set',
            noBinding: 'Unbound',
            modify: 'Modify',
            bindingInfo: 'Binding information',
            general: 'General settings',
            selectLang: 'Setting language',
            userAgreement: 'Terms of Service',
            privacyAgreement: 'Privacy agreement',
            cancelAccount: 'Delete account',
            exitLogin: 'Logout',
            cancelAccountTip: 'Confirm to cancel the account. After cancellation, the account will not be usable and cannot be restored.',
            lang: {
                en_US: 'English',
                ko_KR: '한국어',
            },
            logoutDialogTitle: 'Logout',
            logoutDialogMessage: 'Are you sure you want to log out?',
            initSettingPwd: 'Please bind your email or mobile phone number first',
        },
    },
    utils: {
        estimateTime: {
            arrivalTimeValue1: 'Estimated today ({0})',
            arrivalTimeValue2: 'Estimated before tomorrow ({0})',
            arrivalTimeValue3: 'Estimated day after tomorrow ({0}) ago',
            arrivalTimeValue4: 'Estimated {0} ago',
            arrivalTimeValue5: 'Estimated {0}',
        },
        http: {
            serveError: 'Network abnormality, please try again later',
        },
        index: {
            hoursAgo: '{0} hour ago',
            minAgo: '{0} min ago',
            secondsAgo: '{0} seconds ago',
            yesterday: 'Yesterday',
            sunday: 'Sunday',
            monday: 'Monday',
            tuesday: 'Tuesday',
            wednesday: 'Wednesday',
            thursday: 'Thursday',
            friday: 'Friday',
            saturday: 'Saturday',
        },
        goods: {
            payName: '{0} and others',
        },
    },
};
