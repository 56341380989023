import { defineStore } from 'pinia'
// 原始数据
// interface AttrStore {
//   attributes: Array<AttributesItem>
//   attributeValues: Array<AttributeValuesItem>
// }

interface AttributesItem {
  id: string
  name: string
  categoryId: string
}

interface AttributeValuesItem {
  id: string
  attributeId: string
  value: string
}

interface AttrStore {
  attributes: any
  attributeValues: any
  attributeIds: any
}

export const useAttrStore = defineStore({
  id: 'attr',
  state: (): AttrStore => {
    return {
      attributes: {},
      attributeValues: {},
      attributeIds: {},
    }
  },
  actions: {
    setAttrStore(data: any) {
      data.attributes.map((item: AttributesItem) => {
        this.attributes[item.id] = item.name
      })
      data.attributeValues.map((item: AttributeValuesItem) => {
        this.attributeValues[item.id] = item.value
      })
      data.attributeValues.map((item: AttributeValuesItem) => {
        this.attributeIds[item.id] = item.attributeId
      })
    },
  },
})
