import { i18nt } from '@/plugins/i18n'
import { utils } from 'icool-base'
export * from './event'

export const getTimeAgo = (seconds: number): string => {
  const now = new Date().getTime()
  const past = now - seconds

  const secondsInMs = 1000
  const minutesInMs = secondsInMs * 60
  const hoursInMs = minutesInMs * 60
  const daysInMs = hoursInMs * 24
  const monthsInMs = daysInMs * 30 // Assuming 30 days in a month
  const yearsInMs = daysInMs * 365 // Assuming 365 days in a year

  const years = Math.floor(past / yearsInMs)
  const months = Math.floor((past % yearsInMs) / monthsInMs)
  const days = Math.floor((past % monthsInMs) / daysInMs)
  const hours = Math.floor((past % daysInMs) / hoursInMs)
  const minutes = Math.floor((past % hoursInMs) / minutesInMs)
  const secondsLeft = Math.floor((past % minutesInMs) / secondsInMs)
  if (years > 0) {
    return utils.timestampToTime(seconds, 'YYYY.MM.DD')
  } else if (months > 0) {
    return utils.timestampToTime(seconds, 'YYYY.MM.DD')
  } else if (days > 0) {
    return utils.timestampToTime(seconds, 'MM.DD hh:mm')
    // } else if (hours > 12) {
    //   return utils.timestampToTime(seconds, 'MM.DD hh:mm')
  } else if (hours > 0) {
    return i18nt('h5.utils.index.hoursAgo', [hours])
  } else if (minutes > 0) {
    return i18nt('h5.utils.index.minAgo', [minutes])
  } else if (secondsLeft > 0) {
    return i18nt('h5.utils.index.secondsAgo', [secondsLeft])
  }

  return i18nt('h5.utils.index.secondsAgo', ['1'])
}

export const addCommas = (num: number) => {
  if (isNaN(num)) return num
  return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}

export const formatNumber = (num: number) => {
  if (num < 1000) {
    return num
  } else if (num < 10000) {
    return `${Math.floor(num / 1000)}000+`
  } else if (num < 100000) {
    return `${Math.floor(num / 10000)}W+`
  } else if (num < 10000000) {
    return `${Math.floor(num / 100000)}0W+`
  } else {
    return `${Math.floor(num / 1000000)}00W+`
  }
}

export const base64ToBlob = (base64: string) => {
  const MIMEAndCode = base64.split(';base64,')
  const contentType = MIMEAndCode[0].split(':')[1]
  const rawCode = window.atob(MIMEAndCode[1])
  const rawCodeLength = rawCode.length
  const uInt8Array = new Uint8Array(rawCodeLength)
  for (let i = 0; i < rawCodeLength; i++) {
    uInt8Array[i] = rawCode.charCodeAt(i)
  }
  const fileBolb = new Blob([uInt8Array], {
    type: contentType,
  })
  return fileBolb
}

/**
 * 格式化时间
 * @param timestamp 时间戳
 * @returns 
 *  - 当天推送的消息展示具体时间（时分）：19:26
    - 前一天推送的消息展示：昨天
    - 本周内推送的消息展示：星期三
    - 本周之前推送的消息展示（年月日）：20/02/21   
 */
export function formatDate(timestamp: number) {
  const now = new Date()
  const msgDate = new Date(timestamp)
  const weekDayNames = [
    i18nt('h5.utils.index.sunday'),
    i18nt('h5.utils.index.monday'),
    i18nt('h5.utils.index.tuesday'),
    i18nt('h5.utils.index.wednesday'),
    i18nt('h5.utils.index.thursday'),
    i18nt('h5.utils.index.friday'),
    i18nt('h5.utils.index.saturday'),
  ]

  // 判断是否为当天
  if (msgDate.toDateString() === now.toDateString()) {
    return `${msgDate.getHours().toString().padStart(2, '0')}:${msgDate.getMinutes().toString().padStart(2, '0')}`
  }

  // 判断是否为昨天
  const yesterday = new Date(now)
  yesterday.setDate(yesterday.getDate() - 1)
  if (msgDate.toDateString() === yesterday.toDateString()) {
    return i18nt('h5.utils.index.yesterday')
  }

  // 判断是否为本周
  const firstDayOfWeek = new Date(now)
  firstDayOfWeek.setDate(now.getDate() - now.getDay() + (now.getDay() === 0 ? -6 : 1)) // 本周一
  firstDayOfWeek.setHours(0, 0, 0, 0) // 设置为本周一的0点
  if (msgDate >= firstDayOfWeek) {
    return weekDayNames[msgDate.getDay()]
  }

  // 本周之前
  return `${msgDate.getFullYear()}/${(msgDate.getMonth() + 1).toString().padStart(2, '0')}/${msgDate.getDate().toString().padStart(2, '0')}`
}

export const defaultAvatar =
  'data:image/jpeg;base64,/9j/4AAQSkZJRgABAQIAOAA4AAD/2wBDAAoHBwgHBgoICAgLCgoLDhgQDg0NDh0VFhEYIx8lJCIfIiEmKzcvJik0KSEiMEExNDk7Pj4+JS5ESUM8SDc9Pjv/wAALCACYAJgBAREA/8QAGQABAAMBAQAAAAAAAAAAAAAAAAIDAQQH/8QAKBAAAgEDAwMEAwEBAAAAAAAAAAECEQMxEiFRkQRBYVKBQhMyoRRx/9oACAEBAAA/APZgACMpxjllUu49q6kHdm/t0Itt5bMNTaw2SV2a+1f+k49x7l0LYzjLDJAAAAxtJVboiid9vaOy5KgAAAMYLYX2tpbrkvTTVU6o0AAjKSiqs5p3HN744IgAAAAEoXHB7Y4OmMlNVRIAxtJVeEctybnKvjwRAAAAAAJW5uEq+PJ1JpqqwaDnvzq9KwslQAAAAAABbYnR6Xh4OgjOWmDZyZAAALoWKqsnT0J/ht8f0rnYoqx39CoAADB1wlqgmVdxLEfkpAABZYjWTb8HSAc16OmdV5KwAAXdvLMfkhddbj6EAAAXdu/2ReAUdw/1RSAACdp0uIi3WTfqYAACVueiVep1JqSqnVGmSkoqrZyzlrk2RAABsXRp+pgAAANjJxw6E/zT5XQg5OTq3UwAAAGtUbXqYAAXWrSpqlvwi7THhdBpXCGlcIaVwhpjwuhTdtJLVH5RSAAbFVkl6krqpcZAAA7FSm2DQADJU0uuKHGAATtKtxE+4jiXwUgAFlu84qj3RfGSkqp1JAEZSUVVsouXXNUWyKwAC7t45l8Fs46oNHJgAABNp1ToWK/NZoyX+h+3+kXfm8URW226t1AAAydcI6IJEjnvwo9Sw8lQAAAAAABbYhV6nhYOgGNJqjwzlnBwlTx4ZEAAAAAAlbg5yp48nUkkqLBoBGUVJUZzTtuD3xyRAAAAAJQtub2xydMYqCoiQABjSao1VFE7DW8d1wVYAAAAyWwsN7y2XBekkqJURoAAAIyhGWUVS7f2vqQdqa+vQi01lMw1JvCZJWpv69Sce39z6FsYRhhEgAD/2Q=='

/**
 * 优先获取用户ID，如果没有，则获取cookie中的游客id
 * @returns {string} 用户ID
 */
export const getUserId = () => {
  const customData = (window as any).dataAc
  const userStore = useUserStore()
  const userIdFromStore = userStore.userInfo?.userId
  const cookieUid = customData?.util?.getCookie(customData?.store?.storeUserUid ?? '')
  const cookieTid = customData?.util?.getCookie(customData?.store?.storeUserTid ?? '')
  const userIdFromCookie = cookieUid || cookieTid

  return userIdFromStore || userIdFromCookie || ''
}

export const getDomain = () => {
  let domain = '.i.cool'
  try {
    domain = `.${window.location.host.split('.').slice(-2).join('.')}`
  } catch (e) {
    console.error(e)
  }
  return domain
}

export const getFabricScale = (fW: number, fH: number, w: number = 996, h: number = 1950) => {
  // w: 996,
  // h: 1950,
  const frameScal = Math.min(w / fW, h / fH)
  const _w = Math.ceil(fW * frameScal)
  const _h = Math.ceil(fH * frameScal)
  return `${_w}*${_h}`
}
