import { defineStore } from 'pinia'
interface EventStore {
  timeInterval: Map<string, number>
}
export const useEventStore = defineStore({
  id: 'event',
  state: (): EventStore => {
    return {
      timeInterval: new Map(),
    }
  },
  actions: {
    setTimeInterval(key: string, timestamp: number, setTime: boolean = false) {
      if (this.timeInterval.has(key) && !setTime) {
        const oldTime: number = this.timeInterval.get(key) as number
        this.timeInterval.delete(key)
        return timestamp - oldTime
      } else {
        this.timeInterval.set(key, timestamp)
      }
    },
  },
})
