import { useBase64 } from '@vueuse/core'
import { useApiBaseGetOssUrl } from '@/composables/base'
import { HWencode } from './hwBase'

/**
 * @description: 图片文件转字符串
 * @param {Blob|File} file 文件
 * @return {String}
 */
export function getImgStr(file: File | Blob): Promise<FileReader['result']> {
  return useBase64(file).promise.value
}

// 图片路径转华为云能访问的路径
interface ParamsImageProcess {
  markTxt?: string // 水印文字 一般是作者名
  resize?: number
  quality?: number
}

export let canUseWebp: boolean | undefined = undefined

function isWebpSupported() {
  if (!Image) {
    return Promise.resolve(false)
  }
  return new Promise((resolve) => {
    const img = new Image()
    img.onload = function () {
      resolve(img.width > 0 && img.height > 0)
    }
    img.onerror = function () {
      resolve(false)
    }
    img.src = 'data:image/webp;base64,UklGRlIAAABXRUJQVlA4WAoAAAASAAAAAAAAAAAAQU5JTQYAAAD/////AABBTk1GJgAAAAAAAAAAAAAAAAAAAGQAAABWUDhMDQAAAC8AAAAQBxAREYiI/gcA'
  })
}

export const isSupportWebp = async () => {
  try {
    if (canUseWebp !== undefined) return canUseWebp
    canUseWebp = Boolean(await isWebpSupported())
    return canUseWebp
  } catch (error) {
    canUseWebp = false
    return canUseWebp
  }
}

export const imgToShow = async (paths: string[], imageProcess?: ParamsImageProcess) => {
  const _paths = paths.filter((i) => Boolean(i))
  if (_paths.length == 0) {
    return paths
  }
  const imgProcessParam: any = {}
  if (imageProcess) {
    let startStr = 'image'
    if (imageProcess.markTxt) {
      const authName = `@${imageProcess.markTxt} | `
      const logo = `${import.meta.env.VITE_OBS_BUCKET}/public/20231218-155011.png?x-image-process=image/resize,h_15`
      const formatProcessImg = `/watermark,image_${HWencode(logo, true)},g_br,x_15,y_18`

      const formatProcessTxt = `/watermark,text_${HWencode(
        authName,
        true,
      )},size_12,color_ffffff,type_${`ZHJvaWRzYW5zZmFsbGJhY2s`},shadow_20,g_br,t_80,x_115,y_15`
      startStr = startStr + formatProcessImg + formatProcessTxt
    }
    if (imageProcess && imageProcess?.resize) {
      // 进行压缩预览
      const formatProcess = `/resize,w_${imageProcess?.resize || 100}`
      startStr = startStr + formatProcess
    }
    const formatStr = (await isSupportWebp()) ? startStr + '/format,webp' : startStr
    if (formatStr != 'image') {
      imgProcessParam['x-image-process'] = formatStr
    }
  }

  const rets = await useApiBaseGetOssUrl({ objectNames: paths, imgProcessParam })
  return paths.map((name) => {
    if (!Boolean(name)) {
      return ''
    }
    return rets.data[name].signedUrl
  })
}

// 以对象形式返回图片obj
export const imgToShowObj = async (paths: string[], imageProcess?: ParamsImageProcess) => {
  const _paths = paths.filter((i) => Boolean(i))
  if (_paths.length == 0) {
    return {}
  }

  const imgProcessParam: any = {}
  if (imageProcess) {
    let startStr = 'image'
    if (imageProcess.markTxt) {
      const authName = `@${imageProcess.markTxt} | `
      const logo = `${import.meta.env.VITE_OBS_BUCKET}/public/20231218-155011.png?x-image-process=image/resize,h_15`
      const formatProcessImg = `/watermark,image_${HWencode(logo, true)},g_br,x_15,y_18`

      const formatProcessTxt = `/watermark,text_${HWencode(
        authName,
        true,
      )},size_12,color_ffffff,type_${`ZHJvaWRzYW5zZmFsbGJhY2s`},shadow_20,g_br,t_80,x_115,y_15`
      startStr = startStr + formatProcessImg + formatProcessTxt
    }
    if (imageProcess && imageProcess?.resize) {
      // 进行压缩预览
      const formatProcess = `/resize,w_${imageProcess?.resize || 100}`
      startStr = startStr + formatProcess
    }
    if (imageProcess && imageProcess?.quality) {
      // 进行压缩预览
      const formatProcess = `/quality,q_${imageProcess?.quality || 100}`
      startStr = startStr + formatProcess
    }

    const formatStr = (await isSupportWebp()) ? startStr + '/format,webp' : startStr
    if (formatStr != 'image') {
      imgProcessParam['x-image-process'] = formatStr
    }
  }
  // imgProcessParam['x-image-process'] = `image/resize,w_${300}`

  const rets = await useApiBaseGetOssUrl({ objectNames: paths, imgProcessParam })
  return rets.data
}
