import { createI18n } from 'vue-i18n'
import { i18n as lang } from 'icool-base'
export const i18n = createI18n({
  legacy: true,
  globalInjection: true,
  locale: 'ko_KR',
  messages: lang,
})
export const i18nt = i18n.global.t
export default defineNuxtPlugin(({ vueApp }) => {
  vueApp.use(i18n)
})
