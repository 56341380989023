import { defineStore } from 'pinia'
export type HistoryType = {
  history: Map<string, string>
}
export const useHistoryStore = defineStore({
  id: 'routerHistory',
  state: (): HistoryType => {
    return {
      history: new Map(),
    }
  },
  actions: {
    deleteItem(key: string) {
      this.history.delete(key)
    },
    addItem(key: string, value: string) {
      this.history.set(key, value)
    },
    cleanHistory() {
      this.history.clear
    },
    getHistory() {
      return this.history
    },
    getHistoryKeys() {
      return Array.from(this.history.keys())
    },
    getHistoryValues() {
      return Array.from(this.history.values())
    },
  },
})
