import { default as _404eE0PMyMrxdMeta } from "/app/package/icool-h5/src/pages/404.vue?macro=true";
import { default as aboutEZcirujW82Meta } from "/app/package/icool-h5/src/pages/about.vue?macro=true";
import { default as aihistoryFZC6dOAWZMMeta } from "/app/package/icool-h5/src/pages/aihistory.vue?macro=true";
import { default as appealQeVKOUEbDFMeta } from "/app/package/icool-h5/src/pages/appeal.vue?macro=true";
import { default as _91id_93GTc3PEJVHGMeta } from "/app/package/icool-h5/src/pages/author/[id].vue?macro=true";
import { default as indexQa9N5Q3V7hMeta } from "/app/package/icool-h5/src/pages/author/index.vue?macro=true";
import { default as index40MFWRqjfxMeta } from "/app/package/icool-h5/src/pages/cart/index.vue?macro=true";
import { default as _91id_93xsvhG8qnB2Meta } from "/app/package/icool-h5/src/pages/creative/article/[id].vue?macro=true";
import { default as listPjR3B7bEAEMeta } from "/app/package/icool-h5/src/pages/creative/list.vue?macro=true";
import { default as videoEvBJt2G4haMeta } from "/app/package/icool-h5/src/pages/creative/video.vue?macro=true";
import { default as indexU3VrKM5RqkMeta } from "/app/package/icool-h5/src/pages/event_oct/index.vue?macro=true";
import { default as index5b9bJNp45PMeta } from "/app/package/icool-h5/src/pages/event/index.vue?macro=true";
import { default as _91id_93FufMXiS7KMMeta } from "/app/package/icool-h5/src/pages/goods/[id].vue?macro=true";
import { default as allcommentMjOzTriz1eMeta } from "/app/package/icool-h5/src/pages/goods/allcomment.vue?macro=true";
import { default as failv4qi4oZh3HMeta } from "/app/package/icool-h5/src/pages/goods/fail.vue?macro=true";
import { default as mall7EulEfqFwNMeta } from "/app/package/icool-h5/src/pages/goods/mall.vue?macro=true";
import { default as resultKXMyXjkLiRMeta } from "/app/package/icool-h5/src/pages/goods/result.vue?macro=true";
import { default as indexUs7o39duxrMeta } from "/app/package/icool-h5/src/pages/index.vue?macro=true";
import { default as loginC73YesUQBUMeta } from "/app/package/icool-h5/src/pages/login.vue?macro=true";
import { default as meBdd3Hasji1Meta } from "/app/package/icool-h5/src/pages/me.vue?macro=true";
import { default as messageclDds3nXXCMeta } from "/app/package/icool-h5/src/pages/message.vue?macro=true";
import { default as modifyemailDUZY5SbFuXMeta } from "/app/package/icool-h5/src/pages/modifyemail.vue?macro=true";
import { default as modifypwdBKHE7bK7KoMeta } from "/app/package/icool-h5/src/pages/modifypwd.vue?macro=true";
import { default as modifytelUFaDIJ52adMeta } from "/app/package/icool-h5/src/pages/modifytel.vue?macro=true";
import { default as _91id_93MPca1ap43xMeta } from "/app/package/icool-h5/src/pages/originality/[id].vue?macro=true";
import { default as detailvhRC5QNaQ7Meta } from "/app/package/icool-h5/src/pages/originality/detail.vue?macro=true";
import { default as indexVWKLdojPkSMeta } from "/app/package/icool-h5/src/pages/originality/index.vue?macro=true";
import { default as caken53oqvyOuXMeta } from "/app/package/icool-h5/src/pages/plays/cake.vue?macro=true";
import { default as controlwm17RlOH5iMeta } from "/app/package/icool-h5/src/pages/plays/control.vue?macro=true";
import { default as cuttoaiBJ2ARj2QA3Meta } from "/app/package/icool-h5/src/pages/plays/cuttoai.vue?macro=true";
import { default as list7NoK1t4iLIMeta } from "/app/package/icool-h5/src/pages/plays/list.vue?macro=true";
import { default as mbtihiKvYjZ3n9Meta } from "/app/package/icool-h5/src/pages/plays/mbti.vue?macro=true";
import { default as preControlb9Jg82YmqvMeta } from "/app/package/icool-h5/src/pages/plays/preControl.vue?macro=true";
import { default as pointsGxyI91rQzkMeta } from "/app/package/icool-h5/src/pages/points.vue?macro=true";
import { default as privacyPczdHfFejnMeta } from "/app/package/icool-h5/src/pages/privacy.vue?macro=true";
import { default as profileeditTTaCSG9SYVMeta } from "/app/package/icool-h5/src/pages/profileedit.vue?macro=true";
import { default as _91id_93yhFEL7oa7hMeta } from "/app/package/icool-h5/src/pages/series/[id].vue?macro=true";
import { default as setting0lH4EsyU9lMeta } from "/app/package/icool-h5/src/pages/setting.vue?macro=true";
import { default as termsza36yajZQhMeta } from "/app/package/icool-h5/src/pages/terms.vue?macro=true";
import { default as testpayzDtfvqg8WSMeta } from "/app/package/icool-h5/src/pages/testpay.vue?macro=true";
import { default as indexIaW78nTQihMeta } from "/app/package/icool-h5/src/pages/user/balance/index.vue?macro=true";
import { default as _91id_93Z0sBmWUOArMeta } from "/app/package/icool-h5/src/pages/user/bill/[id].vue?macro=true";
import { default as browserTuXmP5UlQ2Meta } from "/app/package/icool-h5/src/pages/user/browser.vue?macro=true";
import { default as addZGwWsHkwTfMeta } from "/app/package/icool-h5/src/pages/user/card/add.vue?macro=true";
import { default as listbX7GrY8DnJMeta } from "/app/package/icool-h5/src/pages/user/card/list.vue?macro=true";
import { default as indexVUnbkYESLhMeta } from "/app/package/icool-h5/src/pages/user/collects/index.vue?macro=true";
import { default as comment_45detailvlFH8fv7baMeta } from "/app/package/icool-h5/src/pages/user/comment-detail.vue?macro=true";
import { default as comment_45successm1JthQcSZxMeta } from "/app/package/icool-h5/src/pages/user/comment-success.vue?macro=true";
import { default as commentQci2T4G27yMeta } from "/app/package/icool-h5/src/pages/user/comment.vue?macro=true";
import { default as indexvUFyqexWFyMeta } from "/app/package/icool-h5/src/pages/user/comments/index.vue?macro=true";
import { default as couponsFJffpMLzjtMeta } from "/app/package/icool-h5/src/pages/user/coupons.vue?macro=true";
import { default as followlJrYwIhKgHMeta } from "/app/package/icool-h5/src/pages/user/follow.vue?macro=true";
import { default as goodexpiredWQAzZRuIJ6Meta } from "/app/package/icool-h5/src/pages/user/goodexpired.vue?macro=true";
import { default as indexsViJkNkYZSMeta } from "/app/package/icool-h5/src/pages/user/logistic/index.vue?macro=true";
import { default as detaildT7vnHRM7cMeta } from "/app/package/icool-h5/src/pages/user/order/detail.vue?macro=true";
import { default as listvI7Z3OOibpMeta } from "/app/package/icool-h5/src/pages/user/order/list.vue?macro=true";
import { default as logisticsghMMGR863aMeta } from "/app/package/icool-h5/src/pages/user/order/logistics.vue?macro=true";
import { default as artworkListVUfYvNoKAVMeta } from "/app/package/icool-h5/src/pages/userInfo/artworkList.vue?macro=true";
import { default as following39jC4uFaZAMeta } from "/app/package/icool-h5/src/pages/userInfo/following.vue?macro=true";
import { default as indexqGfapvyNlGMeta } from "/app/package/icool-h5/src/pages/userInfo/index.vue?macro=true";
import { default as indext8Q8narTxQMeta } from "/app/package/icool-h5/src/pages/withdraw/index.vue?macro=true";
import { default as resultEXEZKuMy5nMeta } from "/app/package/icool-h5/src/pages/withdraw/result.vue?macro=true";
import { default as resultErrornNvjULqJokMeta } from "/app/package/icool-h5/src/pages/withdraw/resultError.vue?macro=true";
export default [
  {
    name: _404eE0PMyMrxdMeta?.name ?? "404",
    path: _404eE0PMyMrxdMeta?.path ?? "/404",
    url: "/404",
    meta: _404eE0PMyMrxdMeta || {},
    alias: _404eE0PMyMrxdMeta?.alias || [],
    redirect: _404eE0PMyMrxdMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/404.vue").then(m => m.default || m)
  },
  {
    name: aboutEZcirujW82Meta?.name ?? "about",
    path: aboutEZcirujW82Meta?.path ?? "/about",
    url: "/about",
    meta: aboutEZcirujW82Meta || {},
    alias: aboutEZcirujW82Meta?.alias || [],
    redirect: aboutEZcirujW82Meta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/about.vue").then(m => m.default || m)
  },
  {
    name: aihistoryFZC6dOAWZMMeta?.name ?? "aihistory",
    path: aihistoryFZC6dOAWZMMeta?.path ?? "/aihistory",
    url: "/aihistory",
    meta: aihistoryFZC6dOAWZMMeta || {},
    alias: aihistoryFZC6dOAWZMMeta?.alias || [],
    redirect: aihistoryFZC6dOAWZMMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/aihistory.vue").then(m => m.default || m)
  },
  {
    name: appealQeVKOUEbDFMeta?.name ?? "appeal",
    path: appealQeVKOUEbDFMeta?.path ?? "/appeal",
    url: "/appeal",
    meta: appealQeVKOUEbDFMeta || {},
    alias: appealQeVKOUEbDFMeta?.alias || [],
    redirect: appealQeVKOUEbDFMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/appeal.vue").then(m => m.default || m)
  },
  {
    name: _91id_93GTc3PEJVHGMeta?.name ?? "author-id",
    path: _91id_93GTc3PEJVHGMeta?.path ?? "/author/:id()",
    meta: _91id_93GTc3PEJVHGMeta || {},
    alias: _91id_93GTc3PEJVHGMeta?.alias || [],
    redirect: _91id_93GTc3PEJVHGMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/author/[id].vue").then(m => m.default || m)
  },
  {
    name: indexQa9N5Q3V7hMeta?.name ?? "author",
    path: indexQa9N5Q3V7hMeta?.path ?? "/author",
    url: "/author",
    meta: indexQa9N5Q3V7hMeta || {},
    alias: indexQa9N5Q3V7hMeta?.alias || [],
    redirect: indexQa9N5Q3V7hMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/author/index.vue").then(m => m.default || m)
  },
  {
    name: index40MFWRqjfxMeta?.name ?? "cart",
    path: index40MFWRqjfxMeta?.path ?? "/cart",
    url: "/cart",
    meta: index40MFWRqjfxMeta || {},
    alias: index40MFWRqjfxMeta?.alias || [],
    redirect: index40MFWRqjfxMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/cart/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93xsvhG8qnB2Meta?.name ?? "creative-article-id",
    path: _91id_93xsvhG8qnB2Meta?.path ?? "/creative/article/:id()",
    meta: _91id_93xsvhG8qnB2Meta || {},
    alias: _91id_93xsvhG8qnB2Meta?.alias || [],
    redirect: _91id_93xsvhG8qnB2Meta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/creative/article/[id].vue").then(m => m.default || m)
  },
  {
    name: listPjR3B7bEAEMeta?.name ?? "creative-list",
    path: listPjR3B7bEAEMeta?.path ?? "/creative/list",
    url: "/creative/list",
    meta: listPjR3B7bEAEMeta || {},
    alias: listPjR3B7bEAEMeta?.alias || [],
    redirect: listPjR3B7bEAEMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/creative/list.vue").then(m => m.default || m)
  },
  {
    name: videoEvBJt2G4haMeta?.name ?? "creative-video",
    path: videoEvBJt2G4haMeta?.path ?? "/creative/video",
    url: "/creative/video",
    meta: videoEvBJt2G4haMeta || {},
    alias: videoEvBJt2G4haMeta?.alias || [],
    redirect: videoEvBJt2G4haMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/creative/video.vue").then(m => m.default || m)
  },
  {
    name: indexU3VrKM5RqkMeta?.name ?? "event_oct",
    path: indexU3VrKM5RqkMeta?.path ?? "/event_oct",
    url: "/event_oct",
    meta: indexU3VrKM5RqkMeta || {},
    alias: indexU3VrKM5RqkMeta?.alias || [],
    redirect: indexU3VrKM5RqkMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/event_oct/index.vue").then(m => m.default || m)
  },
  {
    name: index5b9bJNp45PMeta?.name ?? "event",
    path: index5b9bJNp45PMeta?.path ?? "/event",
    url: "/event",
    meta: index5b9bJNp45PMeta || {},
    alias: index5b9bJNp45PMeta?.alias || [],
    redirect: index5b9bJNp45PMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/event/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93FufMXiS7KMMeta?.name ?? "goods-id",
    path: _91id_93FufMXiS7KMMeta?.path ?? "/goods/:id()",
    meta: _91id_93FufMXiS7KMMeta || {},
    alias: _91id_93FufMXiS7KMMeta?.alias || [],
    redirect: _91id_93FufMXiS7KMMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/goods/[id].vue").then(m => m.default || m)
  },
  {
    name: allcommentMjOzTriz1eMeta?.name ?? "goods-allcomment",
    path: allcommentMjOzTriz1eMeta?.path ?? "/goods/allcomment",
    url: "/goods/allcomment",
    meta: allcommentMjOzTriz1eMeta || {},
    alias: allcommentMjOzTriz1eMeta?.alias || [],
    redirect: allcommentMjOzTriz1eMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/goods/allcomment.vue").then(m => m.default || m)
  },
  {
    name: failv4qi4oZh3HMeta?.name ?? "goods-fail",
    path: failv4qi4oZh3HMeta?.path ?? "/goods/fail",
    url: "/goods/fail",
    meta: failv4qi4oZh3HMeta || {},
    alias: failv4qi4oZh3HMeta?.alias || [],
    redirect: failv4qi4oZh3HMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/goods/fail.vue").then(m => m.default || m)
  },
  {
    name: mall7EulEfqFwNMeta?.name ?? "goods-mall",
    path: mall7EulEfqFwNMeta?.path ?? "/goods/mall",
    url: "/goods/mall",
    meta: mall7EulEfqFwNMeta || {},
    alias: mall7EulEfqFwNMeta?.alias || [],
    redirect: mall7EulEfqFwNMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/goods/mall.vue").then(m => m.default || m)
  },
  {
    name: resultKXMyXjkLiRMeta?.name ?? "goods-result",
    path: resultKXMyXjkLiRMeta?.path ?? "/goods/result",
    url: "/goods/result",
    meta: resultKXMyXjkLiRMeta || {},
    alias: resultKXMyXjkLiRMeta?.alias || [],
    redirect: resultKXMyXjkLiRMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/goods/result.vue").then(m => m.default || m)
  },
  {
    name: indexUs7o39duxrMeta?.name ?? "index",
    path: indexUs7o39duxrMeta?.path ?? "/",
    url: "/",
    meta: indexUs7o39duxrMeta || {},
    alias: indexUs7o39duxrMeta?.alias || [],
    redirect: indexUs7o39duxrMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: loginC73YesUQBUMeta?.name ?? "login",
    path: loginC73YesUQBUMeta?.path ?? "/login",
    url: "/login",
    meta: loginC73YesUQBUMeta || {},
    alias: loginC73YesUQBUMeta?.alias || [],
    redirect: loginC73YesUQBUMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/login.vue").then(m => m.default || m)
  },
  {
    name: meBdd3Hasji1Meta?.name ?? "me",
    path: meBdd3Hasji1Meta?.path ?? "/me",
    url: "/me",
    meta: meBdd3Hasji1Meta || {},
    alias: meBdd3Hasji1Meta?.alias || [],
    redirect: meBdd3Hasji1Meta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/me.vue").then(m => m.default || m)
  },
  {
    name: messageclDds3nXXCMeta?.name ?? "message",
    path: messageclDds3nXXCMeta?.path ?? "/message",
    url: "/message",
    meta: messageclDds3nXXCMeta || {},
    alias: messageclDds3nXXCMeta?.alias || [],
    redirect: messageclDds3nXXCMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/message.vue").then(m => m.default || m)
  },
  {
    name: modifyemailDUZY5SbFuXMeta?.name ?? "modifyemail",
    path: modifyemailDUZY5SbFuXMeta?.path ?? "/modifyemail",
    url: "/modifyemail",
    meta: modifyemailDUZY5SbFuXMeta || {},
    alias: modifyemailDUZY5SbFuXMeta?.alias || [],
    redirect: modifyemailDUZY5SbFuXMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/modifyemail.vue").then(m => m.default || m)
  },
  {
    name: modifypwdBKHE7bK7KoMeta?.name ?? "modifypwd",
    path: modifypwdBKHE7bK7KoMeta?.path ?? "/modifypwd",
    url: "/modifypwd",
    meta: modifypwdBKHE7bK7KoMeta || {},
    alias: modifypwdBKHE7bK7KoMeta?.alias || [],
    redirect: modifypwdBKHE7bK7KoMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/modifypwd.vue").then(m => m.default || m)
  },
  {
    name: modifytelUFaDIJ52adMeta?.name ?? "modifytel",
    path: modifytelUFaDIJ52adMeta?.path ?? "/modifytel",
    url: "/modifytel",
    meta: modifytelUFaDIJ52adMeta || {},
    alias: modifytelUFaDIJ52adMeta?.alias || [],
    redirect: modifytelUFaDIJ52adMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/modifytel.vue").then(m => m.default || m)
  },
  {
    name: _91id_93MPca1ap43xMeta?.name ?? "originality-id",
    path: _91id_93MPca1ap43xMeta?.path ?? "/originality/:id()",
    meta: _91id_93MPca1ap43xMeta || {},
    alias: _91id_93MPca1ap43xMeta?.alias || [],
    redirect: _91id_93MPca1ap43xMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/originality/[id].vue").then(m => m.default || m)
  },
  {
    name: detailvhRC5QNaQ7Meta?.name ?? "originality-detail",
    path: detailvhRC5QNaQ7Meta?.path ?? "/originality/detail",
    url: "/originality/detail",
    meta: detailvhRC5QNaQ7Meta || {},
    alias: detailvhRC5QNaQ7Meta?.alias || [],
    redirect: detailvhRC5QNaQ7Meta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/originality/detail.vue").then(m => m.default || m)
  },
  {
    name: indexVWKLdojPkSMeta?.name ?? "originality",
    path: indexVWKLdojPkSMeta?.path ?? "/originality",
    url: "/originality",
    meta: indexVWKLdojPkSMeta || {},
    alias: indexVWKLdojPkSMeta?.alias || [],
    redirect: indexVWKLdojPkSMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/originality/index.vue").then(m => m.default || m)
  },
  {
    name: caken53oqvyOuXMeta?.name ?? "plays-cake",
    path: caken53oqvyOuXMeta?.path ?? "/plays/cake",
    url: "/plays/cake",
    meta: caken53oqvyOuXMeta || {},
    alias: caken53oqvyOuXMeta?.alias || [],
    redirect: caken53oqvyOuXMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/plays/cake.vue").then(m => m.default || m)
  },
  {
    name: controlwm17RlOH5iMeta?.name ?? "plays-control",
    path: controlwm17RlOH5iMeta?.path ?? "/plays/control",
    url: "/plays/control",
    meta: controlwm17RlOH5iMeta || {},
    alias: controlwm17RlOH5iMeta?.alias || [],
    redirect: controlwm17RlOH5iMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/plays/control.vue").then(m => m.default || m)
  },
  {
    name: cuttoaiBJ2ARj2QA3Meta?.name ?? "plays-cuttoai",
    path: cuttoaiBJ2ARj2QA3Meta?.path ?? "/plays/cuttoai",
    url: "/plays/cuttoai",
    meta: cuttoaiBJ2ARj2QA3Meta || {},
    alias: cuttoaiBJ2ARj2QA3Meta?.alias || [],
    redirect: cuttoaiBJ2ARj2QA3Meta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/plays/cuttoai.vue").then(m => m.default || m)
  },
  {
    name: list7NoK1t4iLIMeta?.name ?? "plays-list",
    path: list7NoK1t4iLIMeta?.path ?? "/plays/list",
    url: "/plays/list",
    meta: list7NoK1t4iLIMeta || {},
    alias: list7NoK1t4iLIMeta?.alias || [],
    redirect: list7NoK1t4iLIMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/plays/list.vue").then(m => m.default || m)
  },
  {
    name: mbtihiKvYjZ3n9Meta?.name ?? "plays-mbti",
    path: mbtihiKvYjZ3n9Meta?.path ?? "/plays/mbti",
    url: "/plays/mbti",
    meta: mbtihiKvYjZ3n9Meta || {},
    alias: mbtihiKvYjZ3n9Meta?.alias || [],
    redirect: mbtihiKvYjZ3n9Meta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/plays/mbti.vue").then(m => m.default || m)
  },
  {
    name: preControlb9Jg82YmqvMeta?.name ?? "plays-preControl",
    path: preControlb9Jg82YmqvMeta?.path ?? "/plays/preControl",
    url: "/plays/preControl",
    meta: preControlb9Jg82YmqvMeta || {},
    alias: preControlb9Jg82YmqvMeta?.alias || [],
    redirect: preControlb9Jg82YmqvMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/plays/preControl.vue").then(m => m.default || m)
  },
  {
    name: pointsGxyI91rQzkMeta?.name ?? "points",
    path: pointsGxyI91rQzkMeta?.path ?? "/points",
    url: "/points",
    meta: pointsGxyI91rQzkMeta || {},
    alias: pointsGxyI91rQzkMeta?.alias || [],
    redirect: pointsGxyI91rQzkMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/points.vue").then(m => m.default || m)
  },
  {
    name: privacyPczdHfFejnMeta?.name ?? "privacy",
    path: privacyPczdHfFejnMeta?.path ?? "/privacy",
    url: "/privacy",
    meta: privacyPczdHfFejnMeta || {},
    alias: privacyPczdHfFejnMeta?.alias || [],
    redirect: privacyPczdHfFejnMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: profileeditTTaCSG9SYVMeta?.name ?? "profileedit",
    path: profileeditTTaCSG9SYVMeta?.path ?? "/profileedit",
    url: "/profileedit",
    meta: profileeditTTaCSG9SYVMeta || {},
    alias: profileeditTTaCSG9SYVMeta?.alias || [],
    redirect: profileeditTTaCSG9SYVMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/profileedit.vue").then(m => m.default || m)
  },
  {
    name: _91id_93yhFEL7oa7hMeta?.name ?? "series-id",
    path: _91id_93yhFEL7oa7hMeta?.path ?? "/series/:id()",
    meta: _91id_93yhFEL7oa7hMeta || {},
    alias: _91id_93yhFEL7oa7hMeta?.alias || [],
    redirect: _91id_93yhFEL7oa7hMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/series/[id].vue").then(m => m.default || m)
  },
  {
    name: setting0lH4EsyU9lMeta?.name ?? "setting",
    path: setting0lH4EsyU9lMeta?.path ?? "/setting",
    url: "/setting",
    meta: setting0lH4EsyU9lMeta || {},
    alias: setting0lH4EsyU9lMeta?.alias || [],
    redirect: setting0lH4EsyU9lMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/setting.vue").then(m => m.default || m)
  },
  {
    name: termsza36yajZQhMeta?.name ?? "terms",
    path: termsza36yajZQhMeta?.path ?? "/terms",
    url: "/terms",
    meta: termsza36yajZQhMeta || {},
    alias: termsza36yajZQhMeta?.alias || [],
    redirect: termsza36yajZQhMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: testpayzDtfvqg8WSMeta?.name ?? "testpay",
    path: testpayzDtfvqg8WSMeta?.path ?? "/testpay",
    url: "/testpay",
    meta: testpayzDtfvqg8WSMeta || {},
    alias: testpayzDtfvqg8WSMeta?.alias || [],
    redirect: testpayzDtfvqg8WSMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/testpay.vue").then(m => m.default || m)
  },
  {
    name: indexIaW78nTQihMeta?.name ?? "user-balance",
    path: indexIaW78nTQihMeta?.path ?? "/user/balance",
    url: "/user/balance",
    meta: indexIaW78nTQihMeta || {},
    alias: indexIaW78nTQihMeta?.alias || [],
    redirect: indexIaW78nTQihMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/user/balance/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93Z0sBmWUOArMeta?.name ?? "user-bill-id",
    path: _91id_93Z0sBmWUOArMeta?.path ?? "/user/bill/:id()",
    meta: _91id_93Z0sBmWUOArMeta || {},
    alias: _91id_93Z0sBmWUOArMeta?.alias || [],
    redirect: _91id_93Z0sBmWUOArMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/user/bill/[id].vue").then(m => m.default || m)
  },
  {
    name: browserTuXmP5UlQ2Meta?.name ?? "user-browser",
    path: browserTuXmP5UlQ2Meta?.path ?? "/user/browser",
    url: "/user/browser",
    meta: browserTuXmP5UlQ2Meta || {},
    alias: browserTuXmP5UlQ2Meta?.alias || [],
    redirect: browserTuXmP5UlQ2Meta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/user/browser.vue").then(m => m.default || m)
  },
  {
    name: addZGwWsHkwTfMeta?.name ?? "user-card-add",
    path: addZGwWsHkwTfMeta?.path ?? "/user/card/add",
    url: "/user/card/add",
    meta: addZGwWsHkwTfMeta || {},
    alias: addZGwWsHkwTfMeta?.alias || [],
    redirect: addZGwWsHkwTfMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/user/card/add.vue").then(m => m.default || m)
  },
  {
    name: listbX7GrY8DnJMeta?.name ?? "user-card-list",
    path: listbX7GrY8DnJMeta?.path ?? "/user/card/list",
    url: "/user/card/list",
    meta: listbX7GrY8DnJMeta || {},
    alias: listbX7GrY8DnJMeta?.alias || [],
    redirect: listbX7GrY8DnJMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/user/card/list.vue").then(m => m.default || m)
  },
  {
    name: indexVUnbkYESLhMeta?.name ?? "user-collects",
    path: indexVUnbkYESLhMeta?.path ?? "/user/collects",
    url: "/user/collects",
    meta: indexVUnbkYESLhMeta || {},
    alias: indexVUnbkYESLhMeta?.alias || [],
    redirect: indexVUnbkYESLhMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/user/collects/index.vue").then(m => m.default || m)
  },
  {
    name: comment_45detailvlFH8fv7baMeta?.name ?? "user-comment-detail",
    path: comment_45detailvlFH8fv7baMeta?.path ?? "/user/comment-detail",
    url: "/user/comment-detail",
    meta: comment_45detailvlFH8fv7baMeta || {},
    alias: comment_45detailvlFH8fv7baMeta?.alias || [],
    redirect: comment_45detailvlFH8fv7baMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/user/comment-detail.vue").then(m => m.default || m)
  },
  {
    name: comment_45successm1JthQcSZxMeta?.name ?? "user-comment-success",
    path: comment_45successm1JthQcSZxMeta?.path ?? "/user/comment-success",
    url: "/user/comment-success",
    meta: comment_45successm1JthQcSZxMeta || {},
    alias: comment_45successm1JthQcSZxMeta?.alias || [],
    redirect: comment_45successm1JthQcSZxMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/user/comment-success.vue").then(m => m.default || m)
  },
  {
    name: commentQci2T4G27yMeta?.name ?? "user-comment",
    path: commentQci2T4G27yMeta?.path ?? "/user/comment",
    url: "/user/comment",
    meta: commentQci2T4G27yMeta || {},
    alias: commentQci2T4G27yMeta?.alias || [],
    redirect: commentQci2T4G27yMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/user/comment.vue").then(m => m.default || m)
  },
  {
    name: indexvUFyqexWFyMeta?.name ?? "user-comments",
    path: indexvUFyqexWFyMeta?.path ?? "/user/comments",
    url: "/user/comments",
    meta: indexvUFyqexWFyMeta || {},
    alias: indexvUFyqexWFyMeta?.alias || [],
    redirect: indexvUFyqexWFyMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/user/comments/index.vue").then(m => m.default || m)
  },
  {
    name: couponsFJffpMLzjtMeta?.name ?? "user-coupons",
    path: couponsFJffpMLzjtMeta?.path ?? "/user/coupons",
    url: "/user/coupons",
    meta: couponsFJffpMLzjtMeta || {},
    alias: couponsFJffpMLzjtMeta?.alias || [],
    redirect: couponsFJffpMLzjtMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/user/coupons.vue").then(m => m.default || m)
  },
  {
    name: followlJrYwIhKgHMeta?.name ?? "user-follow",
    path: followlJrYwIhKgHMeta?.path ?? "/user/follow",
    url: "/user/follow",
    meta: followlJrYwIhKgHMeta || {},
    alias: followlJrYwIhKgHMeta?.alias || [],
    redirect: followlJrYwIhKgHMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/user/follow.vue").then(m => m.default || m)
  },
  {
    name: goodexpiredWQAzZRuIJ6Meta?.name ?? "user-goodexpired",
    path: goodexpiredWQAzZRuIJ6Meta?.path ?? "/user/goodexpired",
    url: "/user/goodexpired",
    meta: goodexpiredWQAzZRuIJ6Meta || {},
    alias: goodexpiredWQAzZRuIJ6Meta?.alias || [],
    redirect: goodexpiredWQAzZRuIJ6Meta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/user/goodexpired.vue").then(m => m.default || m)
  },
  {
    name: indexsViJkNkYZSMeta?.name ?? "user-logistic",
    path: indexsViJkNkYZSMeta?.path ?? "/user/logistic",
    url: "/user/logistic",
    meta: indexsViJkNkYZSMeta || {},
    alias: indexsViJkNkYZSMeta?.alias || [],
    redirect: indexsViJkNkYZSMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/user/logistic/index.vue").then(m => m.default || m)
  },
  {
    name: detaildT7vnHRM7cMeta?.name ?? "user-order-detail",
    path: detaildT7vnHRM7cMeta?.path ?? "/user/order/detail",
    url: "/user/order/detail",
    meta: detaildT7vnHRM7cMeta || {},
    alias: detaildT7vnHRM7cMeta?.alias || [],
    redirect: detaildT7vnHRM7cMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/user/order/detail.vue").then(m => m.default || m)
  },
  {
    name: listvI7Z3OOibpMeta?.name ?? "user-order-list",
    path: listvI7Z3OOibpMeta?.path ?? "/user/order/list",
    url: "/user/order/list",
    meta: listvI7Z3OOibpMeta || {},
    alias: listvI7Z3OOibpMeta?.alias || [],
    redirect: listvI7Z3OOibpMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/user/order/list.vue").then(m => m.default || m)
  },
  {
    name: logisticsghMMGR863aMeta?.name ?? "user-order-logistics",
    path: logisticsghMMGR863aMeta?.path ?? "/user/order/logistics",
    url: "/user/order/logistics",
    meta: logisticsghMMGR863aMeta || {},
    alias: logisticsghMMGR863aMeta?.alias || [],
    redirect: logisticsghMMGR863aMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/user/order/logistics.vue").then(m => m.default || m)
  },
  {
    name: artworkListVUfYvNoKAVMeta?.name ?? "userInfo-artworkList",
    path: artworkListVUfYvNoKAVMeta?.path ?? "/userInfo/artworkList",
    url: "/userInfo/artworkList",
    meta: artworkListVUfYvNoKAVMeta || {},
    alias: artworkListVUfYvNoKAVMeta?.alias || [],
    redirect: artworkListVUfYvNoKAVMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/userInfo/artworkList.vue").then(m => m.default || m)
  },
  {
    name: following39jC4uFaZAMeta?.name ?? "userInfo-following",
    path: following39jC4uFaZAMeta?.path ?? "/userInfo/following",
    url: "/userInfo/following",
    meta: following39jC4uFaZAMeta || {},
    alias: following39jC4uFaZAMeta?.alias || [],
    redirect: following39jC4uFaZAMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/userInfo/following.vue").then(m => m.default || m)
  },
  {
    name: indexqGfapvyNlGMeta?.name ?? "userInfo",
    path: indexqGfapvyNlGMeta?.path ?? "/userInfo",
    url: "/userInfo",
    meta: indexqGfapvyNlGMeta || {},
    alias: indexqGfapvyNlGMeta?.alias || [],
    redirect: indexqGfapvyNlGMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/userInfo/index.vue").then(m => m.default || m)
  },
  {
    name: indext8Q8narTxQMeta?.name ?? "withdraw",
    path: indext8Q8narTxQMeta?.path ?? "/withdraw",
    url: "/withdraw",
    meta: indext8Q8narTxQMeta || {},
    alias: indext8Q8narTxQMeta?.alias || [],
    redirect: indext8Q8narTxQMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/withdraw/index.vue").then(m => m.default || m)
  },
  {
    name: resultEXEZKuMy5nMeta?.name ?? "withdraw-result",
    path: resultEXEZKuMy5nMeta?.path ?? "/withdraw/result",
    url: "/withdraw/result",
    meta: resultEXEZKuMy5nMeta || {},
    alias: resultEXEZKuMy5nMeta?.alias || [],
    redirect: resultEXEZKuMy5nMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/withdraw/result.vue").then(m => m.default || m)
  },
  {
    name: resultErrornNvjULqJokMeta?.name ?? "withdraw-resultError",
    path: resultErrornNvjULqJokMeta?.path ?? "/withdraw/resultError",
    url: "/withdraw/resultError",
    meta: resultErrornNvjULqJokMeta || {},
    alias: resultErrornNvjULqJokMeta?.alias || [],
    redirect: resultErrornNvjULqJokMeta?.redirect || undefined,
    component: () => import("/app/package/icool-h5/src/pages/withdraw/resultError.vue").then(m => m.default || m)
  }
]