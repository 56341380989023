import revive_payload_client_YTGlmQWWT5 from "/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_neSs9z3UJp from "/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_VeD7Ba96AP from "/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_VTarsGAzgb from "/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import plugin_vue3_xKuw1km5sV from "/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/package/icool-h5/.nuxt/components.plugin.mjs";
import prefetch_client_8FoqvPFTot from "/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import chunk_reload_client_SeG0EjL5Ec from "/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import check_outdated_build_client_LIYcCMJD18 from "/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_10gaYeCiJK from "/app/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.mjs";
import router_IdvUeGedhu from "/app/package/icool-h5/src/plugins/router.ts";
import pxtovw_8NDrC4mb0I from "/app/package/icool-h5/src/plugins/pxtovw.ts";
import i18n_YCWKwfVXF6 from "/app/package/icool-h5/src/plugins/i18n.ts";
import route_middleware_CCsgTRkfKU from "/app/package/icool-h5/src/plugins/route-middleware.ts";
import vant_a3hGDiLsu0 from "/app/package/icool-h5/src/plugins/vant.ts";
export default [
  revive_payload_client_YTGlmQWWT5,
  unhead_neSs9z3UJp,
  router_VeD7Ba96AP,
  payload_client_VTarsGAzgb,
  plugin_vue3_xKuw1km5sV,
  components_plugin_KR1HBZs4kY,
  prefetch_client_8FoqvPFTot,
  chunk_reload_client_SeG0EjL5Ec,
  check_outdated_build_client_LIYcCMJD18,
  plugin_10gaYeCiJK,
  router_IdvUeGedhu,
  pxtovw_8NDrC4mb0I,
  i18n_YCWKwfVXF6,
  route_middleware_CCsgTRkfKU,
  vant_a3hGDiLsu0
]