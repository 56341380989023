import { defineStore } from 'pinia'
interface OrderStore {
  addrId: string
}
export const useOrderStore = defineStore({
  id: 'order',
  state: (): OrderStore => {
    return {
      addrId: '',
    }
  },
  actions: {
    setAddrId(addrId: any) {
      // 手动处理 存放uid到localStorage中 用户id: ACUUID 游客id: ACUTID,用户token: ACUTOKEN
      this.addrId = addrId
    },
  },
})
