import Http from '@/utils/http'
// const useApiBaseXxxx = () => {

// }

// oss 图片转可访问的url
export const useApiBaseGetOssUrl = (params: { objectNames: string[]; imgProcessParam: any }) => {
  return Http.post('/api/comm/client/file/get', params)
}
// 获取oss图片上传临时路径
export const useApiBaseGetOssUploadUrl = (params: { objectNames: string[] }) => {
  return Http.post('/api/comm/client/file/put', { ...params, contentType: 'text/plain' })
}

// oss图片上传
export const useApiBaseOssUploadFile = (url: string, file: any) => {
  return Http.put(url, file, {
    headers: {
      'Content-Type': 'text/plain',
    },
  })
}

// 为前端连接 mqtt 生成 jwt token,用于鉴权
export const useApiBaseGetMqttToken = () => {
  return Http.get('/api/comm/client/mqtt/jwt')
}

export const getGlobalConfig = (params?: { page?: string }) => {
  return Http.get('/api/comm/client/config/global', params || {})
}
