import { ref } from 'vue'

export default (callback: Function, delay: number) => {
  const timeoutId = ref<any>(0)
  const start = ref<number>(0)
  const remaning = ref<number>(delay)
  const pause = () => {
    clearTimeout(timeoutId.value)
    remaning.value -= new Date().getTime() - start.value
  }
  const play = () => {
    start.value = new Date().getTime()
    if (remaning.value > 0) {
      timeoutId.value = setTimeout(() => {
        callback()
      }, remaning.value)
    }
  }
  play()
  return {
    pause,
    play,
  }
}
